import { Navigate, Route } from 'react-router-dom';
import { useIipService } from 'src/apis/iip-service/use-iip-service';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { MaintenanceMessagesOverview } from 'src/pages/market-messages/maintenance-messages/overview';
import { UrgentMarketMessagesOverview } from 'src/pages/market-messages/urgent-market-messages/overview';

export const MarketMessages = () => {
  const iipService = useIipService();

  if (!iipService.response) return <PageSpinner />;

  return (
    <RootRoutes>
      <Route
        path="/market-messages"
        element={<Navigate to="/market-messages/urgent-market-messages" />}
      />
      <Route
        path="/market-messages/urgent-market-messages"
        element={<UrgentMarketMessagesOverview iipService={iipService} />}
      />
      <Route
        path="/market-messages/maintenance-messages"
        element={<MaintenanceMessagesOverview />}
      />
      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
