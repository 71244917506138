import type {
  AuctionDetail,
  AuctionLongTerm,
  AuctionReverse,
  AuctionShortTerm,
} from 'src/apis/monolith/types';

export function isLongTermAuction(
  auction: AuctionDetail
): auction is AuctionLongTerm {
  return auction.algorithmType === 'LONG_TERM';
}

export function isShortTermAuction(
  auction: AuctionDetail
): auction is AuctionShortTerm {
  return auction.algorithmType === 'SHORT_TERM';
}

export function isReverseAuction(
  auction: AuctionDetail
): auction is AuctionReverse {
  return auction.algorithmType === 'REVERSE';
}
