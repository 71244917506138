import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import styled from 'styled-components';
import type { OptionalAssignmentServiceRequest } from 'src/apis/assignment-service/use-optional-assignment-service';
import type { OptionalIipServiceRequest } from 'src/apis/iip-service/use-iip-service';
import { FadeIn } from 'src/components/animation/fade-in';
import { Button } from 'src/components/buttons-and-actions/button';
import { NetworkPointSearch } from 'src/components/domain-specifics/network-points/network-point-search';
import { Container } from 'src/components/layout/container';

import {
  HeaderSecondaryBackground,
  HeaderSecondaryCenter,
} from 'src/components/layout/header/secondary-nav';
import { SecondaryNavAggregateEu } from 'src/components/layout/header/secondary-nav-aggregate-eu';
import { SecondaryNavDashboard } from 'src/components/layout/header/secondary-nav-dashboard';
import { SecondaryNavGdm } from 'src/components/layout/header/secondary-nav-gdm';
import { SecondaryNavIip } from 'src/components/layout/header/secondary-nav-iip';
import { SecondaryNavLng } from 'src/components/layout/header/secondary-nav-lng';
import { SecondaryNavReporting } from 'src/components/layout/header/secondary-nav-reporting';
import { SecondaryNavStorage } from 'src/components/layout/header/secondary-nav-storage';
import { SecondaryNavTransport } from 'src/components/layout/header/secondary-nav-transport';
import type { ActiveSection } from 'src/components/layout/header/types';
import type { MobileNavState } from 'src/components/layout/header/use-mobile-nav';
import { Stack } from 'src/components/layout/stack';
import type { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';

const MobileNav = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem 1.5rem;
  border-top: 0.1rem solid #e7e7e7;
`;

type HeaderSecondaryProps = {
  isSnamAdmin: boolean;
  activeSection: ActiveSection;
  mobileNav: MobileNavState;
  iipService: OptionalIipServiceRequest;
  assignmentService?: Successful<OptionalAssignmentServiceRequest>;
};

export const HeaderSecondary: FC<HeaderSecondaryProps> = ({
  isSnamAdmin,
  activeSection,
  mobileNav,
  iipService,
  assignmentService,
}) => {
  const { minTablet } = useBreakpoints();

  const links = (
    <>
      <SecondaryNavDashboard
        isSnamAdmin={isSnamAdmin}
        activeSection={activeSection}
        assignmentService={assignmentService}
      />
      <SecondaryNavTransport activeSection={activeSection} />
      <SecondaryNavStorage activeSection={activeSection} />
      <SecondaryNavLng activeSection={activeSection} />
      <SecondaryNavReporting activeSection={activeSection} />
      <SecondaryNavGdm activeSection={activeSection} />
      {activeSection.aggregateEu && <SecondaryNavAggregateEu />}
      <SecondaryNavIip activeSection={activeSection} iipService={iipService} />
    </>
  );
  return (
    <HeaderSecondaryBackground>
      <Container>
        <HeaderSecondaryCenter>
          <FadeIn>
            <Stack
              flow="column"
              gap={2.4}
              justifyContent="space-between"
              style={{ display: 'flex' /* safari fix (UXX-151) */ }}
            >
              <div>{minTablet && links}</div>

              {minTablet ? (
                <div style={{ flexShrink: 0 /* safari fix (UXX-151) */ }}>
                  <NetworkPointSearch />
                </div>
              ) : (
                <Button
                  mode="inverse"
                  onClick={() =>
                    mobileNav.setValue(
                      mobileNav.value === 'secondary' ? null : 'secondary'
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faBars}
                    aria-label="Toggle secondary navigation"
                  />
                </Button>
              )}
            </Stack>
          </FadeIn>
        </HeaderSecondaryCenter>
      </Container>

      {!minTablet && mobileNav.value === 'secondary' && (
        <MobileNav>
          <div onClick={() => mobileNav.setValue(null)}>{links}</div>
          <NetworkPointSearch />
        </MobileNav>
      )}
    </HeaderSecondaryBackground>
  );
};
