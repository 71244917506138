import { useEffect } from 'react';
import type { NetworkPointRequest } from 'src/apis/monolith/use-network-point';
import { useAxios, type Successful } from 'src/hooks/use-axios';
import type { CapacityGraph } from 'src/pages/transport/network-points/details/point-details/types';

type Options = {
  oldNetworkPointId: string;
  networkPoint: Successful<NetworkPointRequest>;
};

type Params = {
  oldNetworkPointId: string;
};

export function useNwpCapacityGraph({
  oldNetworkPointId,
  networkPoint,
}: Options) {
  const auctions = useAxios(
    (axios, baseConfig, { oldNetworkPointId }: Params) =>
      axios.request<CapacityGraph[]>({
        ...baseConfig,
        // note: this endpoint reports 404s and 410s as 400 :(
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/capacityGraph/${oldNetworkPointId}`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (networkPoint.response.data.physicalUnit === 'M3_H') return;
    auctions.execute({ oldNetworkPointId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldNetworkPointId]);

  if (networkPoint.response.data.physicalUnit === 'M3_H') return;

  return auctions;
}

export type NwpCapacityGraphRequest = ReturnType<typeof useNwpCapacityGraph>;
