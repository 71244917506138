import type { FC } from 'react';
import { lazy } from 'react';
import { useOurProfiler } from 'src/hooks/use-our-profiler';

const Page = lazy(() => import('./page'));

export const ReportingStandard: FC = () => {
  useOurProfiler('ReportingStandard');
  return <Page />;
};
