import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import type { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import {
  useOrganisationAssignedLegacyPermissions,
  type OrganisationAssignedLegacyPermissionsRequest,
} from 'src/apis/authorization-service/use-legacy-permissions';
import { usePermissionGroup } from 'src/apis/authorization-service/use-permission-group';
import { usePermissionGroups } from 'src/apis/authorization-service/use-permission-groups';
import { usePermissions } from 'src/apis/authorization-service/use-permissions';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  authorizationService: Successful<AuthorizationServiceRequest>;
};

export const CopyPermissionGroup: FC<Props> = ({ authorizationService }) => {
  const permissions = usePermissions({ authorizationService });
  const permissionGroups = usePermissionGroups({
    authorizationService,
  });

  const { permissionGroupId } = useParams<{ permissionGroupId: string }>();
  const permissionGroup = usePermissionGroup({
    permissionGroups,
    permissionGroupId,
  });

  const organisationLegacyPermissions =
    useOrganisationAssignedLegacyPermissions({ authorizationService });

  if (!permissions) throw new Forbidden();
  if (!permissionGroups) throw new Forbidden();
  if (!permissionGroup) throw new Forbidden();

  const isNewFlowEnabled = authorizationService.response.data.isNewFlowEnabled;
  if (
    !permissions.response ||
    !permissionGroups.response ||
    !permissionGroup.response ||
    (!isNewFlowEnabled && !organisationLegacyPermissions?.response)
  )
    return <PageSpinner />;

  return (
    <Page
      organisationLegacyPermissions={
        isNewFlowEnabled
          ? undefined
          : (organisationLegacyPermissions as Successful<OrganisationAssignedLegacyPermissionsRequest>)
      }
      createLink={permissionGroups.response.data._links.create}
      permissions={permissions}
      permissionGroup={permissionGroup}
    />
  );
};
