import { useEffect } from 'react';
import type { MapPrototypeRoot } from 'src/apis/prototype-map/types';
import { useAxios } from 'src/hooks/use-axios';

type ExecuteProps = {
  mapPrototype: string;
};

export function useMapPrototype() {
  const request = useAxios(
    (axios, baseConfig, { mapPrototype }: ExecuteProps) =>
      axios.request<MapPrototypeRoot>({
        ...baseConfig,
        url: mapPrototype,
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: 'map-prototype-root',
      onError(error) {
        return error;
      },
    }
  );
  const mapPrototype = PRISMA_CONFIG.mapPrototype;

  useEffect(() => {
    if (!mapPrototype) return;
    request.execute({ mapPrototype });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPrototype]);

  if (!mapPrototype) return;

  return request;
}

export type MapPrototypeRequest = ReturnType<typeof useMapPrototype>;
