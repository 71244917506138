import type { FC } from 'react';
import { GroupWrap } from 'src/components/group-wrap';
import type { ActiveSection } from 'src/components/layout/header/types';
import { Link } from 'src/components/navigation/link';
import { useBreakpoints } from 'src/hooks/use-breakpoints';

export const isLngActive = (pathname: string) => pathname.startsWith('/lng');

export const SecondaryNavLng: FC<{ activeSection: ActiveSection }> = ({
  activeSection,
}) => {
  const { minTablet } = useBreakpoints();

  if (!activeSection.lng) return null;

  return (
    <GroupWrap
      gap={[1, 2]}
      data-testid="secondary-nav"
      alignItems={!minTablet ? 'stretch' : 'center'}
      flexDirection={!minTablet ? 'column' : 'row'}
    >
      <Link mode="secondary" to="/lng/offers">
        LNG Offers
      </Link>
    </GroupWrap>
  );
};
