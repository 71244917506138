import type { FC } from 'react';
import { lazy, useMemo } from 'react';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import type { AssignmentSettingsRequest } from 'src/apis/assignment-service/use-assignment-settings';
import { useAssignmentSettings } from 'src/apis/assignment-service/use-assignment-settings';
import type { DvgwConfigurationRequest } from 'src/apis/assignment-service/use-dvgw-configuration';
import { useOptionalDvgwConfiguration } from 'src/apis/assignment-service/use-dvgw-configuration';
import type { RequiredDocumentsRequest } from 'src/apis/assignment-service/use-required-documents';
import { useRequiredDocuments } from 'src/apis/assignment-service/use-required-documents';
import {
  useOptionalUserAssignmentAutoApproval,
  type UserAssignmentAutoApprovalRequest,
} from 'src/apis/assignment-service/use-user-assignment-auto-approval';
import type { ContactsParams } from 'src/apis/organisation-service/types';
import { useContacts } from 'src/apis/organisation-service/use-contacts';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useTitle } from 'src/hooks/use-title';
import { usePageParams } from 'src/pages/settings/organisation/contacts/use-page-params';
import { Forbidden } from 'src/utils/errors';

const AssignmentContactPoint = lazy(
  () =>
    import(
      'src/pages/settings/assignment-settings/assignment-contact-point/page'
    )
);
const RequiredDocuments = lazy(
  () =>
    import('src/pages/settings/assignment-settings/required-information/page')
);
const DvgwConfiguration = lazy(
  () => import('src/pages/settings/assignment-settings/dvgw-configuration/page')
);
const UserAssignmentAutoApproval = lazy(
  () =>
    import(
      'src/pages/settings/assignment-settings/user-assignment-auto-approval/page'
    )
);

export const AssignmentSettingsRoot: FC<{
  assignmentService: Successful<AssignmentServiceRequest>;
}> = ({ assignmentService }) => {
  if (
    !assignmentService.response.data._links.assignmentSettings ||
    !assignmentService.response.data._links.requiredDocuments
  ) {
    throw new Forbidden();
  }

  const assignmentSettings = useAssignmentSettings(
    assignmentService.response.data._links.assignmentSettings.href
  );
  const requiredDocuments = useRequiredDocuments(
    assignmentService.response.data._links.requiredDocuments.href
  );

  const dvgwConfiguration = useOptionalDvgwConfiguration(
    assignmentService.response.data._links
      .getAssignmentSettingsDvgwConfiguration?.href
  );

  const userAssignmentAutoApproval = useOptionalUserAssignmentAutoApproval(
    assignmentService.response.data._links
      .getAssignmentSettingsUserAssignmentAutoApproval?.href
  );

  if (
    !assignmentSettings.response ||
    !requiredDocuments.response ||
    dvgwConfiguration?.response === null ||
    userAssignmentAutoApproval?.response === null
  )
    return <PageSpinner />;

  return (
    <Page
      assignmentSettings={assignmentSettings}
      requiredDocuments={requiredDocuments}
      dvgwConfiguration={dvgwConfiguration}
      userAssignmentAutoApproval={userAssignmentAutoApproval}
    />
  );
};

type AssignmentServiceProps = {
  assignmentSettings: Successful<AssignmentSettingsRequest>;
  requiredDocuments: Successful<RequiredDocumentsRequest>;
  dvgwConfiguration?: Successful<DvgwConfigurationRequest>;
  userAssignmentAutoApproval?: Successful<UserAssignmentAutoApprovalRequest>;
};

const Page: FC<AssignmentServiceProps> = ({
  assignmentSettings,
  requiredDocuments,
  dvgwConfiguration,
  userAssignmentAutoApproval,
}) => {
  const title = 'Assignment Settings';
  useTitle(title);

  const pageParams = usePageParams();

  const params = useMemo(
    () =>
      ({
        limit: pageParams.value.pageSize,
        offset: pageParams.value.start,
      }) satisfies ContactsParams,
    [pageParams.value]
  );

  const contacts = useContacts({
    url: assignmentSettings.response.data._links.getAssignmentContacts.href,
    params,
  });

  if (!contacts.response) return <PageSpinner />;

  return (
    <>
      <PageHeader title={title} />
      <Stack gap={2}>
        <AssignmentContactPoint
          assignmentSettings={assignmentSettings}
          contacts={contacts}
          pageParams={pageParams}
        />
        {dvgwConfiguration && (
          <DvgwConfiguration dvgwConfiguration={dvgwConfiguration} />
        )}
        {userAssignmentAutoApproval && (
          <UserAssignmentAutoApproval
            userAssignmentAutoApproval={userAssignmentAutoApproval}
          />
        )}
        <RequiredDocuments requiredInformation={requiredDocuments} />
      </Stack>
    </>
  );
};

export default Page;
