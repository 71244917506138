import type { FC } from 'react';
import { lazy } from 'react';
import { Navigate, Route, useLocation, useParams } from 'react-router-dom';
import {
  useAssignmentServicePublic,
  type AssignmentServiceRequest,
} from 'src/apis/assignment-service/use-assignment-service-root';
import { useOrganisationAssignment } from 'src/apis/assignment-service/use-organisation-assignment';
import { useUserAssignment } from 'src/apis/assignment-service/use-user-assignment';
import { useUserAssignmentApplication } from 'src/apis/assignment-service/use-user-assignment-applications';
import { useShipperUserData } from 'src/apis/monolith/use-shipper-user-data';
import { useTso } from 'src/apis/monolith/use-tso';
import { useOrganisationServicePublic } from 'src/apis/organisation-service/use-organisation-service-public';
import { usePublicOperatorDetails } from 'src/apis/organisation-service/use-public-operator-details';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import SuccessfullyApplied from 'src/pages/operators/assignments/apply-for-assignment/successfully-applied';
import { ApplyForAssignmentOld } from 'src/pages/operators/assignments/apply-for-assignment-old';
import { AssigmentDetails } from 'src/pages/operators/assignments/details';
import { ContractDetails } from 'src/pages/operators/assignments/details/contract-details';
import { FinancialSecurityAssociateForm } from 'src/pages/operators/assignments/details/financial-securities/associate';
import { FinancialSecurityDetails } from 'src/pages/operators/assignments/details/financial-securities/details';
import { RequestRelease } from 'src/pages/operators/assignments/details/financial-securities/details/request-release';
import { SubmitFinancialSecurity } from 'src/pages/operators/assignments/details/financial-securities/submit';

import UserAssignmentsOverviewPage from 'src/pages/operators/assignments/overview';
import { RequestFmct } from 'src/pages/operators/assignments/request-fmct';
import { ParamsGuard } from 'src/utils/params-guard';

const ApplyForNewAssignment = lazy(
  () => import('src/pages/operators/assignments/apply-for-assignment')
);

export const Assignments: FC = () => {
  const assignmentService = useAssignmentServicePublic();
  if (!assignmentService.response) return <PageSpinner />;
  return (
    <RootRoutes>
      <Route
        path="/operators/assignments/overview/*"
        element={
          <UserAssignmentsOverviewPage assignmentService={assignmentService} />
        }
      />
      {/* /operators/operator-assignments/ route will be deleted after removal of the old form */}
      <Route
        path="/operators/operator-assignments/apply-for-assignment"
        element={
          <ApplyForNewAssignment assignmentService={assignmentService} />
        }
      />
      <Route
        path="/operators/operator-assignments/apply-for-assignment/successful"
        element={<SuccessfullyApplied />}
      />

      <Route
        path="/operators/operator-assignments"
        element={<Navigate replace to="/operators/assignments/overview/" />}
      />
      <Route
        path="/operators/operator-assignments/overview"
        element={<Navigate replace to="/operators/assignments/overview/" />}
      />

      {/* OLD CODE */}
      <Route
        path="/operators/assignments"
        element={<Navigate replace to="/operators/assignments/overview" />}
      />

      <Route
        path="/dashboard/operators/assignments/:operatorUuid/request-fmct/*"
        element={<RequestFmct />}
      />

      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/cash-deposit/:financialSecurityId/associate/*"
        element={<FinancialSecurityAssociateForm type="CASH_DEPOSIT" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/rating/:financialSecurityId/associate/*"
        element={<FinancialSecurityAssociateForm type="RATING" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/bank-guarantee/:financialSecurityId/associate/*"
        element={<FinancialSecurityAssociateForm type="BANK_GUARANTEE" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/parent-company-guarantee/:financialSecurityId/associate/*"
        element={
          <FinancialSecurityAssociateForm type="PARENT_COMPANY_GUARANTEE" />
        }
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/cash-deposit/:financialSecurityId"
        element={<FinancialSecurityDetails type="CASH_DEPOSIT" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/rating/:financialSecurityId"
        element={<FinancialSecurityDetails type="RATING" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/bank-guarantee/:financialSecurityId"
        element={<FinancialSecurityDetails type="BANK_GUARANTEE" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/parent-company-guarantee/:financialSecurityId"
        element={<FinancialSecurityDetails type="PARENT_COMPANY_GUARANTEE" />}
      />

      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/:financialSecurityType/:financialSecurityId/request-release"
        element={
          <ParamsGuard
            name="financialSecurityType"
            regExp={/^(bank-guarantee|parent-company-guarantee|rating)$/}
          >
            <RequestRelease />
          </ParamsGuard>
        }
      />

      <Route
        path="/dashboard/operators/assignments/:operatorUuid/contract/:contractType/:contractId/details/*"
        element={
          <ParamsGuard
            name="contractType"
            regExp={/^(fmct|fmct-offer|dect|dect-offer|lect)$/}
          >
            <ContractDetails />
          </ParamsGuard>
        }
      />

      <Route
        path="/dashboard/operators/assignments/:operatorUuid/submit-financial-security/*"
        element={<SubmitFinancialSecurity />}
      />

      <Route
        path="/dashboard/operators/assignments/:operatorUuid/users/:userUuid/*"
        element={<AssigmentDetails />}
      />

      <Route
        path="/operators/assignments/apply-for-assignment/*"
        element={<ApplyForAssignmentOld />}
      />

      <Route
        path="/dashboard/operators/assignments/notifications-redirect/*"
        element={
          <NotificationsRedirect assignmentService={assignmentService} />
        }
      />
      <Route
        path="/dashboard/operators/assignments/documents-notifications-redirect/*"
        element={
          <DocumentsNotificationsRedirect
            assignmentService={assignmentService}
          />
        }
      />

      <Route
        path="/operators/assignments/details/tso/:orgId/user/:userId/*"
        element={<RedirectTsoDetail />}
      />

      <Route
        path="/operators/assignments/operators/:operatorId/contract/*"
        element={<RedirectContractDetail />}
      />

      <Route
        path="/operators/assignments/operators/:operatorUuid/*"
        element={<RedirectOtherPages />}
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};

const RedirectTsoDetail: FC = () => {
  const { orgId, userId } = useParams<{ orgId: string; userId: string }>();
  const { pathname, search, state } = useLocation();
  const tso = useTso(orgId);
  const user = useShipperUserData(userId);

  if (!tso.response || !user.response) return <PageSpinner />;
  return (
    <Navigate
      replace
      to={{
        pathname: pathname.replace(
          `/operators/assignments/details/tso/${orgId}/user/${userId}`,
          `/dashboard/operators/assignments/${tso.response.data.organizationId}/users/${user.response.data.userId}`
        ),
        search,
      }}
      state={state}
    />
  );
};

const RedirectContractDetail: FC = () => {
  const { operatorId } = useParams<{ operatorId: string }>();
  const { pathname, search, state } = useLocation();
  const tso = useTso(operatorId);

  if (!tso.response) return <PageSpinner />;

  return (
    <Navigate
      replace
      to={{
        pathname: pathname.replace(
          `/operators/assignments/operators/${operatorId}`,
          `/dashboard/operators/assignments/${tso.response.data.organizationId}`
        ),
        search,
      }}
      state={state}
    />
  );
};

// this redirect was technically not strictly needed as it was already using uuids,
// but we wanted to align all sub-pages of an operator
const RedirectOtherPages: FC = () => {
  const { operatorUuid } = useParams<{ operatorUuid: string }>();
  const { pathname, search, state } = useLocation();
  return (
    <Navigate
      replace
      to={{
        pathname: pathname.replace(
          `/operators/assignments/operators/${operatorUuid}`,
          `/dashboard/operators/assignments/${operatorUuid}`
        ),
        search,
      }}
      state={state}
    />
  );
};

const DocumentsNotificationsRedirect: FC<{
  assignmentService: Successful<AssignmentServiceRequest>;
}> = ({ assignmentService }) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userAssignmentApplicationId = queryParams.get(
    'userAssignmentApplicationId'
  );
  const currentUser = useAuthenticatedMonolithUser();

  const userAssignmentApplication = useUserAssignmentApplication({
    assignmentService,
    userAssignmentApplicationId,
  });

  if (userAssignmentApplication?.response === null) return <PageSpinner />;

  return (
    <Navigate
      replace
      to={{
        pathname: `/dashboard/operators/assignments/${userAssignmentApplication!.response.data.operatorId}/users/${currentUser.userUuid}`,
        hash: '#documents',
      }}
    />
  );
};

const NotificationsRedirect: FC<{
  assignmentService: Successful<AssignmentServiceRequest>;
}> = ({ assignmentService }) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const orgAssignmentId = queryParams.get('organisationAssignmentId');
  const userAssignmentApplicationId = queryParams.get(
    'userAssignmentApplicationId'
  );
  const userAssignmentId = queryParams.get('userAssignmentId');

  const userAssignmentApplication = useUserAssignmentApplication({
    assignmentService,
    userAssignmentApplicationId,
  });

  const userAssignment = useUserAssignment({
    assignmentService,
    userAssignmentId,
  });

  const organisationAssignment = useOrganisationAssignment({
    assignmentService,
    orgAssignmentId,
  });

  if (
    userAssignmentApplication?.response === null ||
    userAssignment?.response === null ||
    organisationAssignment?.response === null
  )
    return <PageSpinner />;

  const operatorUuid =
    userAssignmentApplication?.response.data.operatorId ??
    userAssignment?.response.data.operatorId ??
    organisationAssignment?.response.data.operatorId;
  const userEmail =
    userAssignmentApplication?.response.data.userEmail ??
    userAssignment?.response.data.userEmail;

  return (
    <NotificationsRedirect2
      operatorUuid={operatorUuid!}
      userEmail={userEmail!}
      organisationAssignment={!!organisationAssignment?.response}
    />
  );
};

const NotificationsRedirect2: FC<{
  operatorUuid: string;
  userEmail: string;
  organisationAssignment: boolean;
}> = ({ operatorUuid, userEmail, organisationAssignment }) => {
  const organisationServicePublic = useOrganisationServicePublic();

  const operatorDetails = usePublicOperatorDetails({
    organisationServicePublic,
    operatorUuid,
  });

  if (!operatorDetails.response) return <PageSpinner />;

  const companyName =
    operatorDetails.response.data.registeredAddress.companyName;

  if (organisationAssignment) {
    return (
      <Navigate
        replace
        to={{
          pathname: `/operators/assignments/overview`,
          search: operatorUuid
            ? `operatorId[label]=${companyName}&operatorId[value]=${operatorUuid}`
            : '',
        }}
      />
    );
  } else {
    return (
      <Navigate
        replace
        to={{
          pathname: `/operators/assignments/overview`,
          search: `?userEmail=${encodeURIComponent(userEmail)}${operatorUuid ? `&operatorId[label]=${companyName}&operatorId[value]=${operatorUuid}` : ''}`,
        }}
      />
    );
  }
};
