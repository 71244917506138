/* eslint-disable prisma-rule/safe-local-storage */
import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { z } from 'zod';

const STORAGE_KEY = 'PRISMA_DEVELOPER_TOOLS';

const stateSchema = z.object({
  gitlabApiToken: z.string().nullable().default(null),
  highlightTestids: z.boolean().default(false),
  caMaMrId: z.string().nullable().default(null),
  aggregateEuMrId: z.string().nullable().default(null),
  authorizationServiceMrId: z.string().nullable().default(null),
  lngMarketingMrId: z.string().nullable().default(null),
  assignmentServiceMrId: z.string().nullable().default(null),
  transportAuctionServiceMrId: z.string().nullable().default(null),
  iipServiceMrId: z.string().nullable().default(null),
});

export type DeveloperToolsState = z.output<typeof stateSchema>;

// the fields that are set to true will be checked for being not null or false
// and if this is the case our "bug icon" will be highlighted
const checkStateForActiveSettings: Record<keyof DeveloperToolsState, boolean> =
  {
    gitlabApiToken: false,
    highlightTestids: true,
    caMaMrId: true,
    aggregateEuMrId: true,
    authorizationServiceMrId: true,
    lngMarketingMrId: true,
    assignmentServiceMrId: true,
    transportAuctionServiceMrId: true,
    iipServiceMrId: true,
  };

type ContextValue = {
  state: DeveloperToolsState;
  setState: Dispatch<SetStateAction<DeveloperToolsState>>;
  hasActiveSettings: boolean;
};

const DeveloperToolsContext = createContext<ContextValue | null>(null);

export function useDeveloperTools() {
  const context = useContext(DeveloperToolsContext);
  return context;
}

const RealDeveloperToolsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState(() => {
    try {
      const storedState = localStorage.getItem(STORAGE_KEY) ?? '{}';
      return stateSchema.parse(JSON.parse(storedState));
    } catch (error) {
      return stateSchema.parse({});
    }
  });

  useEffect(
    () => localStorage.setItem(STORAGE_KEY, JSON.stringify(state)),
    [state]
  );

  const value = useMemo(
    () => ({
      state,
      setState,
      hasActiveSettings: Object.entries(state).some(
        ([key, value]) =>
          checkStateForActiveSettings[key as keyof DeveloperToolsState] &&
          value !== null &&
          value !== false
      ),
    }),
    [state]
  );

  return (
    <DeveloperToolsContext.Provider value={value}>
      {children}
    </DeveloperToolsContext.Provider>
  );
};

export const DeveloperToolsProvider =
  PRISMA_CONFIG.stage === 'ent' || PRISMA_CONFIG.stage === 'local'
    ? RealDeveloperToolsProvider
    : Fragment;
