import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { ShipperResponse } from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';
import type { NoContent } from 'src/utils/no-content';

type HookConfig = {
  shipperUrl: string;
  shipperUuid: string;
};

export function useContractManagementShipper({
  shipperUrl,
  shipperUuid,
}: HookConfig) {
  const shipper = useAxios(
    (axios, baseConfig) => {
      const url = urlTemplate
        .parse(shipperUrl)
        .expand({ shipperId: shipperUuid });

      return axios.request<ShipperResponse | NoContent>({
        ...baseConfig,
        url,
        validateStatus: (status) => status === 200 || status === 404,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    shipper.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shipper;
}

export type ContractManagementShipperRequest = ReturnType<
  typeof useContractManagementShipper
>;
