import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthorizationService } from 'src/apis/authorization-service/use-authorization-service';
import { useOrganisationSettings } from 'src/apis/authorization-service/use-organisation-settings';
import { usePermissionGroups } from 'src/apis/authorization-service/use-permission-groups';
import { usePermissions } from 'src/apis/authorization-service/use-permissions';
import { useUserPermissionGroups } from 'src/apis/authorization-service/use-user-authorization-data';
import { useMyUserDetails } from 'src/apis/organisation-service/use-my-user-details';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useUserDetails } from 'src/pages/settings/organisation/users/details/use-user-details';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const AssignPermissionGroups: FC<Props> = ({ organisationService }) => {
  if (!organisationService.response.data._links.getUserDetails)
    throw new Forbidden();

  const { userId } = useParams<{ userId: string }>();
  const userDetails = useUserDetails({
    url: organisationService.response.data._links.getUserDetails.href,
    userId,
  });
  const currentUser = useMyUserDetails({ organisationService });

  const authorizationService = useAuthorizationService();
  const authorizationData = useUserPermissionGroups({
    authorizationService,
    userId,
  });

  const permissionGroups = usePermissionGroups({
    authorizationService,
  });
  const organisationSettings = useOrganisationSettings({
    authorizationService,
  });

  const permissions = usePermissions({ authorizationService });

  if (!permissions) throw new Forbidden();
  if (!authorizationService) throw new Forbidden();
  if (!authorizationData) throw new Forbidden();
  if (!permissionGroups) throw new Forbidden();

  if (
    !permissions.response ||
    !authorizationService.response ||
    !currentUser.response ||
    !userDetails.response ||
    !authorizationData.response ||
    !permissionGroups.response ||
    !organisationSettings?.response
  )
    return <PageSpinner />;

  return (
    <Page
      permissions={permissions}
      currentUser={currentUser}
      userDetails={userDetails}
      organisationService={organisationService}
      authorizationService={authorizationService}
      authorizationData={authorizationData}
      permissionGroups={permissionGroups}
      organisationSettings={organisationSettings}
    />
  );
};
