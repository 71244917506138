import { useFormikContext } from 'formik';
import type { FC, InputHTMLAttributes } from 'react';
import { useEffect, useRef, useId } from 'react';
import { Box } from 'src/components/box';
import { DropdownContent } from 'src/components/buttons-and-actions/button-dropdown';
import { Calendar } from 'src/components/form/datepicker/calendar';
import {
  DateInputField,
  ErrorWrapper,
} from 'src/components/form/datepicker/input-field';
import type { InputTime } from 'src/components/form/datepicker/utils';
import {
  transformValueToInputMask,
  validateDateInput,
} from 'src/components/form/datepicker/utils';
import { ErrorMessage } from 'src/components/form/error-message';
import { useFieldGroup } from 'src/components/form/field-group';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import type { FormFieldProps } from 'src/components/form/form-field-props';
import { FormHint } from 'src/components/form/form-hint';
import { FieldLabel } from 'src/components/form/label';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import { useDropdown } from 'src/hooks/use-dropdown';

type DateInputProps = FormFieldProps & {
  time?: InputTime;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  hideLabel?: boolean;
  nested?: boolean;
  stacked?: boolean;
  initialOpen?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

/**
 * Use this if the user needs to enter a single date (either with or without time).
 *
 * If you need a date range, use the using the [Date Range Picker](../?path=/docs/components-form-date-range-picker--docs) component.
 */
export const DateInput: FC<DateInputProps> = (props) => {
  const { isStacked, minTablet } = useDefaultStacked();

  const {
    time = false,
    name,
    label,
    hideLabel,
    hint,
    info,
    nested,
    stacked = isStacked,
    minBookingDate,
    maxBookingDate,
    markAsRequired,
    initialOpen,
    ...inputProps
  } = props;

  const { setFieldValue, setFieldTouched } = useFormikContext<unknown>();
  const validate = (value: string | null) => validateDateInput(time, value);

  const id = useId();
  const valueRef = useRef('');
  const updateValueRef = (value: string) => (valueRef.current = value);
  const [field, showError, error] = useCustomField<string | null>(name, label, {
    validate() {
      return validate(valueRef.current);
    },
  });

  const formGroup = useFieldGroup();
  useEffect(() => {
    if (formGroup) {
      formGroup.onError({ name, showError });
    }
  }, [showError, formGroup, name]);

  const { active, setActive, setWrapperElement, ...dropdown } = useDropdown({
    placement: 'bottom-start',
    initialActive: initialOpen,
  });

  // Set initial value
  useEffect(() => {
    updateValueRef(transformValueToInputMask(field.value, time));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // field can be set from the outside, so we need to sync it with input
  useEffect(() => {
    const hasError = Boolean(validate(valueRef.current));
    // when there is no formik value, but an error, the user is probably still typing
    if (!field.value && hasError) return;
    const newValue = transformValueToInputMask(field.value, time);
    if (newValue === valueRef.current) return;
    updateValueRef(newValue);
    setFieldValue(name, field.value, false); // even though the value has NOT changed we will trigger a rerender here as updating the value ref alone would not do it
  }, [field.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const fieldId = formGroup?.id || id;
  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;
  const ariaLabel = hideLabel ? displayLabel : undefined;

  const fieldItem = (
    <FieldItem>
      <div ref={setWrapperElement}>
        <DateInputField
          aria-label={ariaLabel}
          {...inputProps}
          referenceRef={dropdown.refs.setReference}
          time={time}
          fieldId={id}
          name={name}
          value={valueRef.current}
          setUserValue={updateValueRef}
          showError={showError}
          setActive={setActive}
          validate={validate}
          onTab={() => setActive(false)}
          onBlur={field.onBlur}
          isoValue={field.value}
        />

        {active && (
          <DropdownContent
            ref={dropdown.refs.setFloating}
            style={dropdown.style}
          >
            <Box>
              <Calendar
                time={time}
                value={field.value ? new Date(field.value) : null}
                minBookingDate={minBookingDate}
                maxBookingDate={maxBookingDate}
                onSelect={(date) => {
                  const dateString = date && date.toISOString();

                  if (dateString !== field.value) {
                    updateValueRef(transformValueToInputMask(dateString, time));
                    setFieldTouched(name, true, false);
                    setFieldValue(name, dateString, true);
                  }

                  // Close only when the user do not need to specify a time.
                  if (!time) {
                    setActive(false);
                  }
                }}
              />

              {hint && <FormHint children={hint} isDisabled={props.disabled} />}

              {showError && error && (
                <ErrorWrapper>
                  <ErrorMessage error={error} label={label} />
                </ErrorWrapper>
              )}
            </Box>
          </DropdownContent>
        )}
      </div>

      {hint && <FormHint children={hint} isDisabled={props.disabled} />}

      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </FieldItem>
  );

  return ((formGroup && !minTablet) || !formGroup) && !hideLabel ? (
    <FieldLayout stacked={stacked}>
      <FieldLabel
        name={name}
        displayLabel={displayLabel}
        fieldId={fieldId}
        info={info}
        showError={showError}
        nested={nested}
      />

      {fieldItem}
    </FieldLayout>
  ) : (
    fieldItem
  );
};
