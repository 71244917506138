import { z } from 'zod';
import type { PlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { directionValues } from 'src/components/data-display/direction';
import { monolithFilterOptionsSchema } from 'src/components/form/select/searchable-multi-select-monolith';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { tsoOptionSchema } from 'src/pages/reporting/my-transactions/tso-searchable-multi-select';

export function getOverviewSurrenderPageParamsSchema(
  platformSettings?: PlatformSettings | null
) {
  const defaultAmount = platformSettings?.amount ?? 'KILO';
  const defaultCost = platformSettings?.cost ?? 'SUBUNIT';
  return z
    .object({
      amountSetting: z.enum(['KILO', 'MEGA']).default(defaultAmount),
      costSetting: z.enum(['UNIT', 'SUBUNIT']).default(defaultCost),
      'bookedCapacity-max': z.coerce.number().nullable().default(null),
      'bookedCapacity-min': z.coerce.number().nullable().default(null),
      networkPointName: monolithFilterOptionsSchema,
      tso: z.array(tsoOptionSchema).default([]),
      direction: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(directionValues)).default([])
      ),
      runtimeFrom: z.string().nullable().default(null),
      runtimeTo: z.string().nullable().default(null),
      dealId: z.string().nullable().default(null),
      favoritesOnly: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(true)
      ),
      sortColumn: z.string().default('dealId'),
      sortAscending: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(true)
      ),
    })
    .merge(getPaginationSchema());
}
