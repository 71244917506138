import type { ComponentPropsWithoutRef, FC } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'src/styles';

type HintProps = ComponentPropsWithoutRef<'aside'> & {
  mode?: 'default' | 'small';
};

const CleanAside: FC<HintProps> = ({ mode, ...props }) => <aside {...props} />;

export const Hint = styled(CleanAside)`
  display: inline-block;
  color: ${Colors.brandLight2};

  ${({ mode }) =>
    mode === 'small' &&
    css`
      font-size: 1.2rem;
    `}
`;
