import { useEffect } from 'react';
import type { IipServiceRootResponse } from 'src/apis/iip-service/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useToast } from 'src/hooks/use-toasts';
import { handleErrorWithNotification } from 'src/utils/handle-error';

export function useIipService() {
  const request = useAxios(
    (axios, baseConfig) =>
      axios.request<IipServiceRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.iipService,
      }),
    { neededOnPageLoad: true, cacheKey: 'iip-service-root' }
  );

  useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return request;
}

export type IipServiceRequest = ReturnType<typeof useIipService>;

export function useOptionalIipService() {
  const notify = useToast();

  const request = useAxios(
    (axios, baseConfig) =>
      axios.request<IipServiceRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.iipService,
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: 'iip-service-root',
      onError(error) {
        if (!isServerError(error, 401)) {
          handleErrorWithNotification({
            error,
            notify,
            setReauthenticate: () => {},
          });
        }
        return error;
      },
    }
  );

  useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return request;
}

export type OptionalIipServiceRequest = ReturnType<
  typeof useOptionalIipService
>;
