import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useFileDownloadUrl } from 'src/apis/document-exchange/use-file-download-url';
import { Button } from 'src/components/buttons-and-actions/button';
import { Tooltip } from 'src/components/overlay/tooltip';
import { setLocationHref } from 'src/utils/location-usage';

export const ButtonUnderlined = styled(Button)`
  text-decoration: underline;
`;

export const DownloadDocumentLink: FC<{
  getCurrentOperatorDocumentFile: string;
  children: ReactNode;
}> = ({ getCurrentOperatorDocumentFile, children }) => {
  const fileDownloadUrlRequest = useFileDownloadUrl(
    getCurrentOperatorDocumentFile
  );

  useEffect(() => {
    if (!fileDownloadUrlRequest.response) return;
    setLocationHref(fileDownloadUrlRequest.response.data.downloadUrl);
  }, [fileDownloadUrlRequest.response]);

  return (
    <Button mode="link" onClick={() => fileDownloadUrlRequest.execute()}>
      <Tooltip content="Content available for download has been provided by the operator. PRISMA assumes no responsibility for any issues or security risks that may arise from using these files.">
        {(targetProps) => (
          <>
            {children} <FontAwesomeIcon icon={faDownload} {...targetProps} />
          </>
        )}
      </Tooltip>
    </Button>
  );
};
