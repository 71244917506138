import type { FC } from 'react';
import { lazy } from 'react';
import type { IipServiceRequest } from 'src/apis/iip-service/use-iip-service';
import type { Successful } from 'src/hooks/use-axios';

const Page = lazy(() => import('./page'));

type Props = {
  iipService: Successful<IipServiceRequest>;
};

export const UrgentMarketMessagesOverview: FC<Props> = ({ iipService }) => {
  return <Page iipService={iipService} />;
};
