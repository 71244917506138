import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  GasInfrastructurePoints,
  GasInfrastructurePointsParams,
} from 'src/apis/prototype-map/types';
import type { MapPrototypeRequest } from 'src/apis/prototype-map/use-map-prototype';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';

type HookProps = {
  mapPrototype?: MapPrototypeRequest;
  params: GasInfrastructurePointsParams;
};

type ExecuteProps = {
  mapPrototype: Successful<MapPrototypeRequest>;
  params: GasInfrastructurePointsParams;
};

export function useInfrastructurePoints({ mapPrototype, params }: HookProps) {
  const request = useAxios(
    (axios, baseConfig, { mapPrototype, params }: ExecuteProps) => {
      const url = urlTemplate
        .parse(mapPrototype.response.data._links.infrastructurePoints.href)
        .expand({
          minLat: params.minLat,
          maxLat: params.maxLat,
          minLon: params.minLon,
          maxLon: params.maxLon,
          type: params.type,
        });

      return axios.request<GasInfrastructurePoints>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!mapPrototype?.response) return;
    request.execute({ mapPrototype, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPrototype?.response]);

  if (!mapPrototype) return;

  return request;
}

export type InfrastructurePointsRequest = ReturnType<
  typeof useInfrastructurePoints
>;
