import type { FC } from 'react';
import { lazy } from 'react';
import type { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import {
  useOrganisationAssignedLegacyPermissions,
  type OrganisationAssignedLegacyPermissionsRequest,
} from 'src/apis/authorization-service/use-legacy-permissions';
import { usePermissionGroups } from 'src/apis/authorization-service/use-permission-groups';
import { usePermissions } from 'src/apis/authorization-service/use-permissions';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  authorizationService: Successful<AuthorizationServiceRequest>;
};

export const CreatePermissionGroup: FC<Props> = ({ authorizationService }) => {
  const permissions = usePermissions({ authorizationService });
  const permissionGroups = usePermissionGroups({
    authorizationService,
  });
  const organisationLegacyPermissions =
    useOrganisationAssignedLegacyPermissions({ authorizationService });

  if (!permissions) throw new Forbidden();
  if (!permissionGroups) throw new Forbidden();
  const isNewFlowEnabled = authorizationService.response.data.isNewFlowEnabled;
  if (
    !permissions.response ||
    !permissionGroups.response ||
    (!isNewFlowEnabled && !organisationLegacyPermissions?.response)
  )
    return <PageSpinner />;

  return (
    <Page
      permissions={permissions}
      permissionGroups={permissionGroups}
      organisationLegacyPermissions={
        isNewFlowEnabled
          ? undefined
          : (organisationLegacyPermissions as Successful<OrganisationAssignedLegacyPermissionsRequest>)
      }
    />
  );
};
