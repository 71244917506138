import type { FC } from 'react';
import { lazy } from 'react';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useOptionalUserAssignmentAutoApproval } from 'src/apis/assignment-service/use-user-assignment-auto-approval';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () =>
    import(
      'src/pages/settings/assignment-settings/user-assignment-auto-approval/edit/page'
    )
);

type AssignmentServiceProps = {
  assignmentService: Successful<AssignmentServiceRequest>;
};

export const EditUserAssignmentAutoApproval: FC<AssignmentServiceProps> = ({
  assignmentService,
}) => {
  if (
    !assignmentService.response.data._links
      .getAssignmentSettingsUserAssignmentAutoApproval
  ) {
    throw new Forbidden();
  }

  const userAssignmentAutoApproval = useOptionalUserAssignmentAutoApproval(
    assignmentService.response.data._links
      .getAssignmentSettingsUserAssignmentAutoApproval.href
  );

  if (!userAssignmentAutoApproval?.response) return <PageSpinner />;

  return <Page userAssignmentAutoApproval={userAssignmentAutoApproval} />;
};
