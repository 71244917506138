import type { FC } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import type { OptionalAssignmentServiceRequest } from 'src/apis/assignment-service/use-optional-assignment-service';
import { GroupWrap } from 'src/components/group-wrap';
import type { ActiveSection } from 'src/components/layout/header/types';
import { ExternalLink, Link } from 'src/components/navigation/link';
import type { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useDirectFcfsFlags } from 'src/hooks/use-direct-fcfs-flags';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';

const angularRoutes = [
  '#/start',
  '#/notifications',
  '#/uploads',
  '#/settings',
  '#/maintenance-messages',
  '#/activity',
  '#/platform-settings',
];

export const isDashboardActive = (pathname: string, hash: string) =>
  pathname.startsWith('/start') ||
  pathname.startsWith('/activity') ||
  pathname.startsWith('/notifications') ||
  pathname.startsWith('/uploads') ||
  pathname.startsWith('/shippers') ||
  pathname.startsWith('/platform-settings') ||
  pathname.startsWith('/maintenance-messages') ||
  pathname.startsWith('/operators') ||
  pathname.startsWith('/shipper') ||
  pathname.startsWith('/dashboard') ||
  pathname.startsWith('/snam-bid-collector') ||
  pathname.startsWith('/direct-fcfs') ||
  (pathname.startsWith('/platform') &&
    angularRoutes.some((angularRoute) => hash.startsWith(angularRoute)));

type SecondaryNavDashboardProps = {
  isSnamAdmin: boolean;
  activeSection: ActiveSection;
  assignmentService?: Successful<OptionalAssignmentServiceRequest>;
};

export const SecondaryNavDashboard: FC<SecondaryNavDashboardProps> = (
  props
) => {
  const { isSnamAdmin, activeSection, assignmentService } = props;
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const { minTablet } = useBreakpoints();
  const { pathname } = useLocation();

  const directFcfsFlags = useDirectFcfsFlags();
  const isDirectFcfsSection = useMatch('/direct-fcfs/*');
  const renderDirectFcfsLink =
    directFcfsFlags.data?._links?.['view-requests'] ||
    directFcfsFlags.data?._links?.['read-product'] ||
    directFcfsFlags.data?._links?.['read-capacity'] ||
    isDirectFcfsSection;

  if (!activeSection.dashboard) return null;
  return (
    <GroupWrap
      gap={[1, 2]}
      data-testid="secondary-nav"
      alignItems={!minTablet ? 'stretch' : 'center'}
      flexDirection={!minTablet ? 'column' : 'row'}
    >
      {monolithUser?.isShipper || monolithUser?.isTso ? (
        <ExternalLink
          mode="secondary"
          href={`${PRISMA_CONFIG.angularUrl}/#/start`}
        >
          Dashboard
        </ExternalLink>
      ) : (
        <Link
          mode="secondary"
          to="/dashboard"
          isActive={pathname === '/dashboard'}
        >
          Dashboard
        </Link>
      )}

      {monolithUser?.isShipper && (
        <ExternalLink
          mode="secondary"
          href={`${PRISMA_CONFIG.angularUrl}/#/activity`}
        >
          Activity
        </ExternalLink>
      )}

      {monolithUser?.currentlyActingWithOrganisationRole === 'SHIPPER' && (
        <Link
          mode="secondary"
          to="/operators"
          isActive={
            pathname.startsWith('/operators') ||
            pathname.startsWith('/dashboard/operators')
          }
        >
          Operators
        </Link>
      )}

      {monolithUser?.isTso && (
        <ExternalLink
          mode="secondary"
          href={`${PRISMA_CONFIG.angularUrl}/#/notifications`}
        >
          Notifications
        </ExternalLink>
      )}

      {monolithUser?.isTso && (
        <ExternalLink
          mode="secondary"
          href={`${PRISMA_CONFIG.angularUrl}/#/uploads`}
        >
          Uploads
        </ExternalLink>
      )}

      {renderDirectFcfsLink && (
        <Link mode="secondary" to="/direct-fcfs">
          Direct FCFS
        </Link>
      )}

      {assignmentService?.response.data._links.assignedUsers &&
        monolithUser?.currentlyActingWithOrganisationRole !== 'SHIPPER' && (
          <Link
            mode="secondary"
            to="/shippers"
            isActive={
              pathname.startsWith('/shippers') ||
              pathname.startsWith('/dashboard/shippers')
            }
          >
            Shippers
          </Link>
        )}

      {monolithUser?.isTso && (
        <ExternalLink
          mode="secondary"
          href={`${PRISMA_CONFIG.angularUrl}/#/maintenance-messages`}
        >
          Maintenance Messages
        </ExternalLink>
      )}

      {isSnamAdmin && (
        <Link mode="secondary" to="/snam-bid-collector">
          Bid Collector
        </Link>
      )}
    </GroupWrap>
  );
};
