import { useEffect, useState } from 'react';
import { useIsEnd2EndTest } from 'src/hooks/use-is-e2e-test';

export const defaultInitialSeconds = 5;

export function useCountdown(initialSeconds = defaultInitialSeconds) {
  const [seconds, setSeconds] = useState(initialSeconds);
  const isEnd2EndTest = useIsEnd2EndTest();

  // speed up countdown within unit and E2E tests
  const delay = process.env.NODE_ENV === 'test' || isEnd2EndTest ? 1 : 1000;

  useEffect(() => {
    if (seconds === 0) return;

    const id = setTimeout(() => setSeconds(seconds - 1), delay);
    return () => clearTimeout(id);
  }, [seconds, delay]);

  return seconds;
}
