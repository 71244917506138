import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { assertContext } from 'src/utils/assert-context';

export type FormModeContextValue = 'regular' | 'filter' | 'search';

const FormModeContext = createContext<FormModeContextValue | undefined>(
  undefined
);

export function useOptionalFormMode() {
  return useContext(FormModeContext);
}

export function useFormMode() {
  const context = useOptionalFormMode();
  assertContext(context, 'FormMode');
  return context!;
}

export const FormModeProvider: FC<{
  mode: FormModeContextValue;
  children: ReactNode;
}> = ({ children, mode }) => {
  return (
    <FormModeContext.Provider value={mode}>{children}</FormModeContext.Provider>
  );
};
