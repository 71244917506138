import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Colors } from 'src/styles';

export const FieldLabel: FC<{
  name?: string;
  displayLabel: string;
  fieldId: string;
  info?: ReactNode;
  showError?: boolean;
  nested?: boolean;
}> = ({ name, displayLabel, fieldId, info, showError, nested }) => {
  return (
    <div>
      <Label
        htmlFor={fieldId}
        error={showError}
        nested={nested}
        data-testid={name ? `${name}Label` : undefined}
      >
        {displayLabel}
      </Label>

      {info && (
        // We use <NoWrap> to keep the label and info icon on the same line.
        // The &nbsp; adds space between them without allowing a line break.
        // This prevents the icon from moving to the next line when the label is long.
        <NoWrap>
          &nbsp;
          <Tooltip content={info} dataTestId={name ? `${name}Info` : undefined}>
            {(targetProps) => <InfoIcon {...targetProps} icon={faInfoCircle} />}
          </Tooltip>
        </NoWrap>
      )}
    </div>
  );
};

// Alignment hack to vertically center the icon with the label
// Basically, set the direct children all align to the baseline (default behaviour unless it's set otherwise)
// but the icon, we align the icon to the text-top, which is the only way
// we get it to be rendered exactly for all of its instances — only then,
// we shift its position by 0.125rem from the top to align it with the label
// note the translate instead of positioning relatively so we don't affect the parent's layout
// TODO: Investigate why the info icon aligns differently than the label and remove this ugly hack
const InfoIcon = styled(FontAwesomeIcon)`
  vertical-align: text-top;
  transform: translateY(
    0.125rem
  ); // .fa has a -0.125rem of vertical-align so we kinda "unset" it with this
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

export const Label = styled.label<{ error?: boolean; nested?: boolean }>`
  font-weight: 700;

  ${({ error }) =>
    error &&
    css`
      color: ${Colors.error};
    `}

  ${({ nested }) =>
    nested &&
    css`
      font-weight: 400;
      /* aligned with checkbox */
      padding-left: 1.8rem;
    `}
`;
