import type { UserOrOrganisationPayloadData } from 'src/apis/assignment-service/types';
import { useAxios } from 'src/hooks/use-axios';

type RequestOptions = {
  url: string;
  data: UserOrOrganisationPayloadData;
};

export function useWithdrawUserAssignment() {
  return useAxios(
    (axios, baseConfig, { url, data }: RequestOptions) =>
      axios.request({
        ...baseConfig,
        method: 'DELETE',
        url,
        data,
      }),
    {
      neededOnPageLoad: false,
    }
  );
}

export type WithdrawUserAssignmentsRequest = ReturnType<
  typeof useWithdrawUserAssignment
>;
