import type { FC } from 'react';
import type { OptionalIipServiceRequest } from 'src/apis/iip-service/use-iip-service';
import { GroupWrap } from 'src/components/group-wrap';
import type { ActiveSection } from 'src/components/layout/header/types';
import { Link } from 'src/components/navigation/link';
import { useBreakpoints } from 'src/hooks/use-breakpoints';

export const isIipActive = (pathname: string) =>
  pathname.startsWith('/market-messages');

export const SecondaryNavIip: FC<{
  activeSection: ActiveSection;
  iipService?: OptionalIipServiceRequest;
}> = ({ activeSection, iipService }) => {
  const { minTablet } = useBreakpoints();

  if (!activeSection.iip) return null;

  return (
    <>
      {iipService && (
        <GroupWrap
          gap={[1, 2]}
          data-testid="secondary-nav"
          alignItems={!minTablet ? 'stretch' : 'center'}
          flexDirection={!minTablet ? 'column' : 'row'}
        >
          <Link mode="secondary" to="/market-messages/urgent-market-messages">
            Urgent Market Messages
          </Link>

          <Link mode="secondary" to="/market-messages/maintenance-messages">
            Maintenance Messages
          </Link>
        </GroupWrap>
      )}
    </>
  );
};
