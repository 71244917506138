import type { FC } from 'react';
import { lazy } from 'react';
import { useAuthorizationService } from 'src/apis/authorization-service/use-authorization-service';
import { useUserAssignedPermissionGroups } from 'src/apis/authorization-service/use-user-assigned-permission-groups';
import { useMyUserDetails } from 'src/apis/organisation-service/use-my-user-details';
import { useOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import type { CognitoUserAttributes } from 'src/hooks/use-cognito-user-attributes';
import { useCognitoUserAttributes } from 'src/hooks/use-cognito-user-attributes';

const Page = lazy(() => import('src/pages/settings/profile/page'));

export const Profile: FC = () => {
  const authorizationService = useAuthorizationService();
  const userAssignedPermissionGroups = useUserAssignedPermissionGroups({
    authorizationService,
  });
  const organisationService = useOrganisationService();
  const myUserDetails = useMyUserDetails({ organisationService });
  const cognitoUserAttributes = useCognitoUserAttributes();

  if (
    !cognitoUserAttributes.response ||
    !myUserDetails.response ||
    userAssignedPermissionGroups?.response === null ||
    authorizationService.response === null
  )
    return <PageSpinner />;

  return (
    <Page
      cognitoUserAttributes={
        cognitoUserAttributes as Successful<CognitoUserAttributes>
      }
      myUserDetails={myUserDetails}
      userAssignedPermissionGroups={userAssignedPermissionGroups}
      isNewFlowEnabled={!!authorizationService.response.data.isNewFlowEnabled}
    />
  );
};
