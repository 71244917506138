import { useEffect } from 'react';
import type { AuctionBidLongTerm } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';

type Options = {
  onlyIfShipper?: boolean;
  auctionId: string;
};

type Params = {
  auctionId: string;
};

export function useUnifiedBids({ auctionId, onlyIfShipper }: Options) {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const ignoreRequest = onlyIfShipper && !monolithUser?.isShipper;

  const unifiedBids = useAxios(
    (axios, baseConfig, { auctionId }: Params) =>
      axios.request<AuctionBidLongTerm[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/${auctionId}/unified-bids`,
        transformResponse(data) {
          // if requested for a short term auction, we get back 204 (data is an empty string)
          if (data === '') return [];
          return JSON.parse(data) as AuctionBidLongTerm[];
        },
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 403)) return error; // missing assignment or permission
        throw error;
      },
    }
  );

  useEffect(() => {
    if (ignoreRequest) return;
    unifiedBids.execute({ auctionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionId]);

  if (ignoreRequest) return;

  return unifiedBids;
}

export type UnifiedBidsRequest = ReturnType<typeof useUnifiedBids>;
