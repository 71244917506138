import { z } from 'zod';

import { requiredOutput } from 'src/components/form/zod-utilities';

export const approveOrganisationAssignmentSchema = z.object({
  tradingMarkets: z
    .enum(['PRIMARY', 'SECONDARY', 'PRIMARY_AND_SECONDARY'])
    .default('PRIMARY'),
});

export const editMarketAccessSchema = approveOrganisationAssignmentSchema;

export const rejectOrganisationOrUserAssignmentSchema = z.object({
  reason: z
    .string()
    .max(250, 'Reason must not exceed 250 characters')
    .nullable()
    .transform(requiredOutput()),
});

export const cancelOrganisationOrUserAssignmentSchema =
  rejectOrganisationOrUserAssignmentSchema;
