import { isBefore } from 'date-fns';
import { useFormikContext } from 'formik';
import type { FC, InputHTMLAttributes, ReactNode } from 'react';
import { useEffect, useRef, useId } from 'react';
import { createPortal } from 'react-dom';
import { Box } from 'src/components/box';
import { DropdownContent } from 'src/components/buttons-and-actions/button-dropdown';
import {
  useIsInitiallyAfter,
  useIsInitiallyBefore,
} from 'src/components/form/datepicker/hooks';
import {
  DateInputField,
  ErrorWrapper,
} from 'src/components/form/datepicker/input-field';
import {
  CalendarContainer,
  RangeCalendar,
} from 'src/components/form/datepicker/range-calendar';
import type { Hour, InputTime } from 'src/components/form/datepicker/utils';
import {
  dateRegExp,
  dateTimeRegExp,
  isDateProvided,
  parseDateMatches,
  parseDateTimeMatches,
  transformValueToInputMask,
  validateDateInput,
} from 'src/components/form/datepicker/utils';
import { ErrorMessage } from 'src/components/form/error-message';
import { FieldGroup } from 'src/components/form/field-group';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import { FormHint } from 'src/components/form/form-hint';
import { InputContainer } from 'src/components/form/inner-addon';
import { Label } from 'src/components/form/label';
import { useCustomField } from 'src/components/form/use-custom-field';
import { Stack } from 'src/components/layout/stack';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import { useDropdown } from 'src/hooks/use-dropdown';
import { useParentZIndex } from 'src/hooks/use-parent-z-index';
import type { StrictOmit } from 'src/utils/utility-types';

type CalendarRangeInputProps = {
  fromName: string;
  fromLabel?: string;
  toName: string;
  toLabel?: string;
  time?: InputTime | [InputTime, InputTime];
  defaultHour?: Hour;
  fromInputProps?: StrictOmit<
    InputHTMLAttributes<HTMLInputElement>,
    'value'
  > & {
    disabledMessage?: ReactNode;
  };
  toInputProps?: StrictOmit<InputHTMLAttributes<HTMLInputElement>, 'value'> & {
    disabledMessage?: ReactNode;
  };
  stackedGroup?: boolean;
  stackedLabel?: boolean;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  groupLabel?: string;
  hideLabel?: boolean;
  interval?: (fromDate: Date) => Date;
  fromHint?: ReactNode;
  toHint?: ReactNode;
  info?: ReactNode;
  /**
   * Appends an asterisk (*) to `fromLabel`.
   *
   * Should be set to true when this field is required, but there are
   * other fields in the containing form that are not required.
   *
   * If all fields in a form are required, the convention is to omit the
   * required marker.
   *
   * If you use this, you need to *manually* handle the asterisk for `groupLabel`!
   */
  fromMarkAsRequired?: boolean;
  /**
   * Appends an asterisk (*) to `toLabel`.
   *
   * Should be set to true when this field is required, but there are
   * other fields in the containing form that are not required.
   *
   * If all fields in a form are required, the convention is to omit the
   * required marker.
   *
   * If you use this, you need to *manually* handle the asterisk for `groupLabel`!
   */
  toMarkAsRequired?: boolean;
  initialOpen?: boolean;
};

export const DateRangeInput: FC<CalendarRangeInputProps> = (props) => {
  const {
    time = false,
    defaultHour,
    fromName,
    fromLabel = 'From',
    toName,
    toLabel = 'To',
    fromInputProps,
    toInputProps,
    stackedGroup,
    stackedLabel,
    minBookingDate,
    maxBookingDate,
    groupLabel,
    hideLabel,
    interval,
    fromHint,
    toHint,
    info,
    fromMarkAsRequired,
    toMarkAsRequired,
    initialOpen,
  } = props;

  const { isStackedLabel, isStackedGroup, minTablet } = useDefaultStacked(
    stackedLabel,
    stackedGroup
  );

  const { setFieldValue, setFieldTouched } = useFormikContext<unknown>();

  // only disable the field, if the initial value is before min booking date,
  // so the field does not become disabled, if the user accidentally enters a date which is too early,
  // so that the user has the chance to change it
  const fromDisabledBecauseInitiallyAfter = useIsInitiallyBefore(
    fromName,
    minBookingDate
  );

  // only disable the field, if the initial value is after max booking date,
  // so the field does not become disabled, if the user accidentally enters a date which is too early,
  // so that the user has the chance to change it
  const toDisabledBecauseInitiallyAfter = useIsInitiallyAfter(
    toName,
    maxBookingDate
  );

  const fromDisabled =
    fromInputProps?.disabled || fromDisabledBecauseInitiallyAfter;

  const toDisabled =
    toInputProps?.disabled ||
    Boolean(interval) ||
    toDisabledBecauseInitiallyAfter;

  const fromTime = Array.isArray(time) ? time[0] : time;
  const toTime = Array.isArray(time) ? time[1] : time;

  const fromId = useId();
  const fromUserValueRef = useRef('');
  const updateFromUserValueRef = (value: string) =>
    (fromUserValueRef.current = value);
  const [fromField, showFromFieldError, fromFieldError] = useCustomField<
    string | null
  >(fromName, fromLabel, {
    validate() {
      const fromValue = fromUserValueRef.current;
      const invalidDate = validateDateInput(fromTime, fromValue);
      if (invalidDate) return invalidDate;

      if (!toDisabled) return; // prefer showing invalid range errors on the end date
      const invalidRange = toAfterFrom({
        fromValue,
        toValue: toUserValueRef.current,
        fromTime,
        toTime,
      });
      if (invalidRange) return 'The start date cannot be after the end date.';
    },
  });

  const toId = useId();
  const toUserValueRef = useRef('');
  const updateToUserValueRef = (value: string) =>
    (toUserValueRef.current = value);

  const [toField, showToFieldError, toFieldError] = useCustomField<
    string | null
  >(toName, toLabel, {
    validate() {
      const toValue = toUserValueRef.current;
      const invalidDate = validateDateInput(toTime, toValue);
      if (invalidDate) return invalidDate;

      if (toDisabled) return; // prefer showing invalid range errors on the end date
      const invalidRange = toAfterFrom({
        fromValue: fromUserValueRef.current,
        toValue,
        fromTime,
        toTime,
      });
      if (invalidRange) return 'The end date must be after the start date.';
    },
  });

  const { active, setActive, setWrapperElement, ...dropdown } = useDropdown({
    placement: 'bottom-start',
    initialActive: initialOpen,
  });
  const parentZIndex = useParentZIndex();

  // Set initial value
  useEffect(() => {
    updateFromUserValueRef(
      transformValueToInputMask(fromField.value, fromTime)
    );
    updateToUserValueRef(transformValueToInputMask(toField.value, toTime));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // field can be set from the outside, so we need to sync it with input
  useEffect(() => {
    const hasError = Boolean(
      validateDateInput(fromTime, fromUserValueRef.current)
    );
    // when there is no formik value, but an error, the user is probably still typing
    if (!fromField.value && hasError) return;
    const newValue = transformValueToInputMask(fromField.value, fromTime);
    if (newValue === fromUserValueRef.current) return;
    updateFromUserValueRef(newValue);
    setFieldValue(fromName, fromField.value, false); // even though the value has NOT changed we will trigger a rerender here as updating the value ref alone would not do it
  }, [fromField.value]); // eslint-disable-line react-hooks/exhaustive-deps

  // field can be set from the outside, so we need to sync it with input
  useEffect(() => {
    const hasError = Boolean(validateDateInput(toTime, toUserValueRef.current));
    // when there is no formik value, but an error, the user is probably still typing
    if (!toField.value && hasError) return;
    const newValue = transformValueToInputMask(toField.value, toTime);
    if (newValue === toUserValueRef.current) return;
    updateToUserValueRef(newValue);
    setFieldValue(toName, toField.value, false); // even though the value has NOT changed we will trigger a rerender here as updating the value ref alone would not do it
  }, [toField.value]); // eslint-disable-line react-hooks/exhaustive-deps

  // Append interval if given
  useEffect(() => {
    if (!interval) return;
    if (!fromField.value) return;

    setFieldValue(
      toName,
      interval(new Date(fromField.value)).toISOString(),
      true
    );
    setActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromField.value, setFieldValue, toName, setActive]);

  const fromDisplayedLabel = fromLabel + (fromMarkAsRequired ? '*' : '');
  const fromInput = (
    <FieldItem>
      <DateInputField
        referenceRef={!isStackedGroup ? dropdown.refs.setReference : undefined}
        time={fromTime}
        fieldId={fromId}
        name={fromName}
        value={fromUserValueRef.current}
        setUserValue={updateFromUserValueRef}
        showError={showFromFieldError}
        setActive={setActive}
        validate={(value) => validateDateInput(fromTime, value)}
        onBlur={fromField.onBlur}
        aria-label={hideLabel ? fromDisplayedLabel : undefined}
        {...fromInputProps}
        disabled={fromDisabled}
        disabledMessage={fromInputProps?.disabledMessage}
        isoValue={fromField.value}
      />

      {fromHint && (
        <FormHint
          children={fromHint}
          isDisabled={props.fromInputProps?.disabled}
        />
      )}

      {showFromFieldError && fromFieldError && (
        <ErrorMessage
          data-testid={`${fromField.name}Error`}
          error={fromFieldError}
          label={fromLabel}
        />
      )}
    </FieldItem>
  );

  const fromFieldItem =
    (groupLabel && minTablet) || hideLabel ? (
      fromInput
    ) : (
      <FieldLayout stacked={isStackedLabel}>
        <Label htmlFor={fromId} error={showFromFieldError}>
          {fromDisplayedLabel}
        </Label>

        {fromInput}
      </FieldLayout>
    );

  const toDisplayedLabel = toLabel + (toMarkAsRequired ? '*' : '');
  const toInput = (
    <FieldItem>
      <DateInputField
        referenceRef={isStackedGroup ? dropdown.refs.setReference : undefined}
        time={toTime}
        fieldId={toId}
        name={toName}
        value={toUserValueRef.current}
        setUserValue={updateToUserValueRef}
        showError={showToFieldError}
        setActive={setActive}
        validate={(value) => validateDateInput(toTime, value)}
        onBlur={toField.onBlur}
        onTab={() => setActive(false)}
        aria-label={hideLabel ? toDisplayedLabel : undefined}
        {...toInputProps}
        disabled={toDisabled}
        disabledMessage={toInputProps?.disabledMessage}
        isoValue={toField.value}
      />

      {toHint && (
        <FormHint children={toHint} isDisabled={props.toInputProps?.disabled} />
      )}

      {showToFieldError && toFieldError && (
        <ErrorMessage
          data-testid={`${toField.name}Error`}
          error={toFieldError}
          label={toLabel}
        />
      )}
    </FieldItem>
  );

  const toFieldItem =
    (groupLabel && minTablet) || hideLabel ? (
      toInput
    ) : (
      <FieldLayout stacked={isStackedLabel}>
        <Label htmlFor={toId} error={showToFieldError}>
          {toDisplayedLabel}
        </Label>

        {toInput}
      </FieldLayout>
    );

  return (
    <div ref={setWrapperElement}>
      <InputContainer>
        {groupLabel && minTablet && !hideLabel ? (
          <FieldGroup
            label={groupLabel}
            stacked={isStackedLabel}
            stackedChildren={isStackedGroup}
            id={fromId}
            info={info}
          >
            {fromFieldItem}
            {toFieldItem}
          </FieldGroup>
        ) : (
          <Stack
            gap={1}
            alignItems="start"
            flow={isStackedGroup ? 'row' : 'column'}
          >
            {fromFieldItem}
            {toFieldItem}
          </Stack>
        )}

        {active &&
          createPortal(
            <DropdownContent
              ref={dropdown.refs.setFloating}
              style={{
                zIndex: parentZIndex + 1,
                ...dropdown.style,
              }}
            >
              <Box>
                <RangeCalendar
                  fromDisabled={
                    fromInputProps?.disabled ||
                    fromDisabledBecauseInitiallyAfter
                  }
                  toDisabled={
                    toInputProps?.disabled ||
                    Boolean(interval) ||
                    toDisabledBecauseInitiallyAfter
                  }
                  time={time}
                  defaultHour={defaultHour}
                  value={{
                    start: fromField.value ? new Date(fromField.value) : null,
                    end: toField.value ? new Date(toField.value) : null,
                  }}
                  minBookingDate={minBookingDate}
                  maxBookingDate={maxBookingDate}
                  onSelect={(date) => {
                    const startDateString =
                      date.start && date.start.toISOString();

                    if (startDateString !== fromField.value) {
                      updateFromUserValueRef(
                        transformValueToInputMask(startDateString, fromTime)
                      );
                      setFieldTouched(fromName, true, false);
                      setFieldValue(fromName, startDateString, true);
                    }

                    const endDateString = date.end && date.end.toISOString();

                    if (endDateString !== toField.value) {
                      updateToUserValueRef(
                        transformValueToInputMask(endDateString, toTime)
                      );
                      // only set "to" field as touched, if endDateString is not null
                      // (if it is null the user is in the middle of a selection and we should not
                      // show errors)
                      setFieldTouched(toName, endDateString !== null, false);
                      setFieldValue(toName, endDateString, true);
                    }

                    if (date.start && date.end && !time) {
                      setActive(false);
                    }
                  }}
                />

                <CalendarContainer>
                  {fromHint && (
                    <FormHint
                      children={fromHint}
                      isDisabled={props.fromInputProps?.disabled}
                    />
                  )}

                  {toHint && (
                    <FormHint
                      children={toHint}
                      isDisabled={props.toInputProps?.disabled}
                    />
                  )}
                </CalendarContainer>

                {((showFromFieldError && fromFieldError) ||
                  (showToFieldError && toFieldError)) && (
                  <ErrorWrapper>
                    {showFromFieldError && fromFieldError && (
                      <ErrorMessage error={fromFieldError} label={fromLabel} />
                    )}

                    {showToFieldError && toFieldError && (
                      <ErrorMessage error={toFieldError} label={toLabel} />
                    )}
                  </ErrorWrapper>
                )}
              </Box>
            </DropdownContent>,
            document.body
          )}
      </InputContainer>
    </div>
  );
};

function toAfterFrom({
  fromValue,
  toValue,
  fromTime,
  toTime,
}: {
  fromValue: string | null;
  toValue: string | null;
  fromTime: InputTime;
  toTime: InputTime;
}) {
  if (!isDateProvided(fromTime, fromValue)) return;
  if (!isDateProvided(toTime, toValue)) return;
  if (validateDateInput(fromTime, fromValue)) return;
  if (validateDateInput(toTime, toValue)) return;

  const fromMatches = fromValue.match(fromTime ? dateTimeRegExp : dateRegExp);
  if (!fromMatches) return;
  const fromDate = fromTime
    ? parseDateTimeMatches(fromMatches)
    : parseDateMatches(fromMatches);

  const toMatches = toValue.match(toTime ? dateTimeRegExp : dateRegExp);
  if (!toMatches) return;
  const toDate = toTime
    ? parseDateTimeMatches(toMatches)
    : parseDateMatches(toMatches);

  return isBefore(toDate, fromDate);
}
