import type {
  AssignmentState,
  AuctionPhaseType,
  BundleRequestOperation,
  CountDownType,
  OrderState,
  ProcessingState,
  SelectableAuctionPhaseType,
  StorageProductType,
  TradeResponseStatus,
} from 'src/apis/monolith/types';
import type { PeriodTypeValue } from 'src/pages/reporting/auctions/components/period-type';
import { capacityLabels } from 'src/pages/reporting/capacity';
import type { TradeProposalState } from 'src/pages/reporting/secondary-tradings/overview/types';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export const productTypeLabels: Record<StorageProductType, string> = {
  SBU: 'SBU',
  STORAGE_VOLUME: 'Storage Volume',
  INJECTION_CAPACITY: 'Injection Capacity',
  WITHDRAWAL_CAPACITY: 'Withdrawal Capacity',
};

export const productTypeOptions = labelsToOptions(productTypeLabels);

export const productTypeValues = labelsToValues(productTypeLabels);

export const processingStateLabels: Record<ProcessingState, string> = {
  CONFIRMED: 'Confirmed',
  COUNTER_PROPOSED: 'Counter Proposed',
  REJECTED: 'Rejected',
  REQUESTED: 'Requested',
};

export const processingStateOptions = labelsToOptions(processingStateLabels);
export const processingStateValues = labelsToValues(processingStateLabels);

export const bundleRequestOperationLabels: Record<
  BundleRequestOperation,
  string
> = {
  CREATE: 'Create Bundle',
  CHANGE: 'Change Bundle',
  DELETE: 'Delete Bundle',
};

export const bundleRequestOperationOptions = labelsToOptions(
  bundleRequestOperationLabels
);
export const bundleRequestOperationValues = labelsToValues(
  bundleRequestOperationLabels
);

export const auctionTitles: Record<PeriodTypeValue, string> = {
  WITHINDAY: 'Within-Day Auctions',
  DAY: 'Day Ahead Auctions',
  MONTH: 'Monthly Auctions',
  QUARTER: 'Quarterly Auctions',
  YEAR: 'Yearly Auctions',
};

export const auctionTitlesShort: Record<PeriodTypeValue, string> = {
  WITHINDAY: 'Within-Day',
  DAY: 'Daily',
  MONTH: 'Monthly',
  QUARTER: 'Quarterly',
  YEAR: 'Yearly',
};

export const countDowns: Record<CountDownType, string> = {
  SUBMISSION_START: 'Submission start in',
  SUBMISSION_DEADLINE: 'Submission deadline in',
  PUBLICATION: 'Publication in',
  AUCTION_START: 'Starts in',
  AUCTION_CLOSE: 'Ends in',
};

export const capacityCategoryValues = labelsToValues(capacityLabels);

export const selectablePhaseTypeLabels: Record<
  SelectableAuctionPhaseType,
  string
> = {
  FINISHED: 'Finished',
  CANCELLED: 'Cancelled',
  UNSUCCESSFUL: 'Unsuccessful',
  TERMINATED: 'Terminated',
};

export const selectablePhaseTypeOptions = labelsToOptions(
  selectablePhaseTypeLabels
);

export const selectablePhaseTypeValues = labelsToValues(
  selectablePhaseTypeLabels
);

export const phaseTypeLabels: Record<AuctionPhaseType, string> = {
  ...selectablePhaseTypeLabels,
  PREOPEN: 'Preopen',
  PUBLISHED: 'Published',
  OPEN: 'Open',
  EVALUATING: 'Evaluating',
  CLOSED: 'Closed',
  SKIPPED: 'Skipped',
  ECONOMIC_TEST_PENDING: 'Economic Test Pending',
};

export const phaseTypeValues = labelsToValues(phaseTypeLabels);

export const tradeProposalStateLabels: Record<TradeProposalState, string> = {
  NEW: 'Draft',
  OPEN: 'Open',
  CLOSED: 'Closed',
  FULFILLED: 'Fulfilled',
  DELETED: 'Deleted',
  WITHDRAWN: 'Withdrawn',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
  EXPIRED: 'Expired',
};

export const tradeResponseStatusLabels: Record<TradeResponseStatus, string> = {
  NEW: 'Draft',
  PENDING: 'Pending',
  APPROVED_EXIT: 'Approved Exit',
  APPROVED_ENTRY: 'Approved Entry',
  FINISHED: 'Finished',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  CANCELLED: 'Cancelled',
};

export const orderStatusLabels: Record<OrderState, string> = {
  OFFERED: 'Offered',
  IN_COMMISSION: 'In Commission',
  PROCESSED: 'Processed',
  CANCELLED: 'Cancelled',
  WAITING: 'Waiting',
};

export const assignmentStatusLabels: Record<AssignmentState, string> = {
  APPROVED: 'Approved',
  NOT_APPROVED: 'Not Approved',
  CANCELED: 'Canceled',
  PENDING: 'Pending',
};

export const assignmentStatusOptions = labelsToOptions(assignmentStatusLabels);
