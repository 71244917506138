import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { OrganisationAssignedLegacyPermissionsResponse } from 'src/apis/authorization-service/types';
import type { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import type { PermissionGroupRequest } from 'src/apis/authorization-service/use-permission-group';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookProps = {
  authorizationService?: AuthorizationServiceRequest;
  permissionGroup?: PermissionGroupRequest;
};

type ExecuteProps = {
  authorizationService?: Successful<AuthorizationServiceRequest>;
  permissionGroup?: Successful<PermissionGroupRequest>;
};

export function useOrganisationAssignedLegacyPermissions({
  authorizationService,
  permissionGroup,
}: HookProps) {
  const request = useAxios(
    (
      axios,
      baseConfig,
      { authorizationService, permissionGroup }: ExecuteProps
    ) => {
      const href =
        authorizationService?.response.data._links
          .organisationAssignedLegacyPermissions?.href ??
        permissionGroup?.response.data._links
          .organisationAssignedLegacyPermissions?.href;

      if (!href) throw new Forbidden();

      const url = urlTemplate.parse(href).expand({});

      return axios.request<OrganisationAssignedLegacyPermissionsResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (
      !authorizationService?.response?.data.isNewFlowEnabled ||
      permissionGroup?.response
    )
      request.execute({
        authorizationService:
          authorizationService as Successful<AuthorizationServiceRequest>,
        permissionGroup: permissionGroup as Successful<PermissionGroupRequest>,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationService?.response, permissionGroup?.response]);

  if (!authorizationService && !permissionGroup) return;

  return request;
}

export type OrganisationAssignedLegacyPermissionsRequest = ReturnType<
  typeof useOrganisationAssignedLegacyPermissions
>;
