import { createContext, useContext, useEffect, useState } from 'react';
import type { SystemInfo } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export const defaultSystemInfo: SystemInfo = {
  branch: '',
  buildDate: '',
  revision: '',
  implementationVersion: '',
  serverTime: '',
  featureMultiYearCapacity: false,
  reversePickupQueueBlocked: false,
  blockingEvent: {
    namespace: '',
    eventType: '',
  },
};

const url = `${PRISMA_CONFIG.monolithApiUrl}/resources/systemInfo`;

const SystemInfoContext = createContext<SystemInfo>(defaultSystemInfo);

export function useSystemInfoRequest() {
  const [catched, setCatched] = useState(false);

  const systemInfo = useAxios(
    (axios, baseConfig) =>
      axios.request<SystemInfo>({
        ...baseConfig,
        url,
        // if the old platform is too slow to respond (>= 10s) a timeout
        // error will be thrown - and we'll fallback to our default values
        timeout: 10000,
      }),
    {
      neededOnPageLoad: true,
      onError() {
        // Special error treatment!
        // This request is done very early in the life cycle of the app.
        // We can't really recover from it as there is nothing in place
        // to recover to. But if we return some usefull default here, the app at least
        // _starts_.
        setCatched(true);
      },
    }
  );

  useEffect(() => {
    systemInfo.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resolved = Boolean(systemInfo.response) || catched;

  return [
    resolved,
    systemInfo.response ? systemInfo.response.data : defaultSystemInfo,
  ] as const;
}

export function useSystemInfo() {
  return useContext(SystemInfoContext);
}

export const SystemInfoProvider = SystemInfoContext.Provider;
