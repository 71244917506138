import { useEffect } from 'react';
import type { GuidedTourResponse } from 'src/apis/authorization-service/types';
import type { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import { useAxios, type Successful } from 'src/hooks/use-axios';

type HookProps = {
  authorizationService: AuthorizationServiceRequest;
};

type ExecuteProps = {
  authorizationService: Successful<AuthorizationServiceRequest>;
};

export function useGuidedTour({ authorizationService }: HookProps) {
  const request = useAxios(
    (axios, baseConfig, { authorizationService }: ExecuteProps) => {
      const url = authorizationService.response.data._links.guidedTour?.href;
      return axios.request<GuidedTourResponse>({
        method: 'GET',
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (!authorizationService.response) return;
    request.execute({ authorizationService });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationService.response]);

  return request;
}

export type GuidedTourRequest = ReturnType<typeof useGuidedTour>;
