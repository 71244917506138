import type { FC } from 'react';
import type { MetaLabelColor } from 'src/components/data-display/meta-label';
import { MetaLabel } from 'src/components/data-display/meta-label';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export type FlowDirection = 'ENTRY' | 'EXIT';
export type DirectionType = FlowDirection | 'BUNDLE';

export const flowDirectionLabels: Record<FlowDirection, string> = {
  ENTRY: 'Entry',
  EXIT: 'Exit',
};
export const flowDirectionOptions = labelsToOptions(flowDirectionLabels);
export const flowDirectionValues = labelsToValues(flowDirectionLabels);

export const directionLabels: Record<DirectionType, string> = {
  ...flowDirectionLabels,
  BUNDLE: 'Exit/Entry',
};
export const directionOptions = labelsToOptions(directionLabels);
export const directionValues = labelsToValues(directionLabels);

function getDirectionLabel(direction: DirectionType): string {
  return {
    ENTRY: 'Entry',
    EXIT: 'Exit',
    BUNDLE: 'Bundle',
  }[direction];
}

export type NetworkPointForDirection =
  | { direction: DirectionType }
  | { direction: FlowDirection; marketArea0: string }
  | { direction: FlowDirection; marketer: string }
  | { direction: FlowDirection; marketAreaName: string }
  | { direction: 'BUNDLE'; marketArea0: string; marketArea1: string }
  | { exit: { marketArea: string }; entry: { marketArea: string } }
  | { exit: { marketer: string }; entry: { marketer: string } }
  | { exit: { marketArea: string } }
  | { exit: { marketer: string } }
  | { entry: { marketArea: string } }
  | { entry: { marketer: string } };

type DirectionBaseProps =
  | ({ direction: DirectionType } & {
      entryMarketArea?: never;
      exitMarketArea?: never;
    })
  | { direction: 'ENTRY'; entryMarketArea: string; exitMarketArea?: never }
  | { direction: 'EXIT'; exitMarketArea: string; entryMarketArea?: never }
  | { direction: 'BUNDLE'; entryMarketArea: string; exitMarketArea: string };

function getDirection(nwp: NetworkPointForDirection): DirectionType {
  if ('direction' in nwp) return nwp.direction;
  if ('exit' in nwp && 'entry' in nwp) return 'BUNDLE';
  if ('exit' in nwp) return 'EXIT';
  if ('entry' in nwp) return 'ENTRY';
  throw `Found unsupported network point type: ${nwp}`;
}

function getExitMarketArea(
  nwp: NetworkPointForDirection,
  direction: DirectionType
) {
  if ('marketArea0' in nwp && direction !== 'ENTRY') return nwp.marketArea0;
  if ('marketAreaName' in nwp && direction === 'EXIT')
    return nwp.marketAreaName;
  if ('marketer' in nwp && direction === 'EXIT') return nwp.marketer;
  if ('exit' in nwp && 'marketArea' in nwp.exit) return nwp.exit.marketArea;
  if ('exit' in nwp && 'marketer' in nwp.exit) return nwp.exit.marketer;
  return undefined;
}

function getEntryMarketArea(
  nwp: NetworkPointForDirection,
  direction: DirectionType
) {
  if ('marketArea0' in nwp && direction === 'ENTRY') return nwp.marketArea0;
  if ('marketArea1' in nwp && direction === 'BUNDLE') return nwp.marketArea1;
  if ('marketAreaName' in nwp && direction === 'ENTRY')
    return nwp.marketAreaName;
  if ('marketer' in nwp && direction === 'ENTRY') return nwp.marketer;
  if ('entry' in nwp && 'marketArea' in nwp.entry) return nwp.entry.marketArea;
  if ('entry' in nwp && 'marketer' in nwp.entry) return nwp.entry.marketer;
  return undefined;
}

export function convertDirectionProps(nwp: NetworkPointForDirection) {
  const direction = getDirection(nwp);
  const exitMarketArea = getExitMarketArea(nwp, direction);
  const entryMarketArea = getEntryMarketArea(nwp, direction);
  return { direction, exitMarketArea, entryMarketArea } as DirectionBaseProps;
}

export type DirectionProps = {
  networkPoint: NetworkPointForDirection;
};

const TooltipContent: FC<DirectionBaseProps> = (props) => {
  const { direction, entryMarketArea, exitMarketArea } = props;
  switch (direction) {
    case 'BUNDLE':
      return (
        <small>
          <Stack
            flow="column"
            templateColumns="1fr 1fr"
            gap={1}
            alignItems="start"
          >
            {exitMarketArea && (
              <span>
                <strong>{directionLabels['EXIT']}</strong>
                <br />
                {exitMarketArea}
              </span>
            )}
            {entryMarketArea && (
              <span>
                <strong>{directionLabels['ENTRY']}</strong>
                <br />
                {entryMarketArea}
              </span>
            )}
          </Stack>
        </small>
      );
    case 'ENTRY':
    case 'EXIT':
      return (
        <small>
          <Stack>
            <strong>{directionLabels[direction]}</strong>
            {entryMarketArea ? entryMarketArea : exitMarketArea}
          </Stack>
        </small>
      );
  }
};

const colorMap: Record<DirectionType, MetaLabelColor> = {
  ENTRY: 'directionEntry',
  EXIT: 'directionExit',
  BUNDLE: 'directionBundle',
};

export const Direction: FC<DirectionProps> = ({ networkPoint }) => {
  const props = convertDirectionProps(networkPoint);
  return (
    <Tooltip
      dataTestId="direction"
      content={
        (props.entryMarketArea || props.exitMarketArea) && (
          <TooltipContent {...props} />
        )
      }
    >
      {(targetProps) => (
        <MetaLabel
          {...targetProps}
          color={colorMap[props.direction]}
          data-testid={props.direction}
        >
          {getDirectionLabel(props.direction)}
        </MetaLabel>
      )}
    </Tooltip>
  );
};
