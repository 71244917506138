import type { FC } from 'react';
import { lazy } from 'react';
import { useMyUserDetails } from 'src/apis/organisation-service/use-my-user-details';
import { useOptionalOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import { useOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { useOrganisationServicePublic } from 'src/apis/organisation-service/use-organisation-service-public';
import { usePublicOperators } from 'src/apis/organisation-service/use-public-operators';
import { PageSpinner } from 'src/components/spinner-container';

const Page = lazy(() => import('./page'));

export const Dashboard: FC = () => {
  const organisationServicePublic = useOrganisationServicePublic();
  const operators = usePublicOperators({
    randomOrder: true,
    organisationServicePublic,
  });

  const organisationService = useOrganisationService();
  const myUserDetails = useMyUserDetails({ organisationService });
  const myOrganisationDetails = useOptionalOrganisationDetails({
    organisationService,
  });

  if (
    !operators.response ||
    !myUserDetails.response ||
    !myOrganisationDetails?.response
  )
    return <PageSpinner />;

  return (
    <Page
      operators={operators}
      myUserDetails={myUserDetails}
      myOrganisationDetails={myOrganisationDetails}
    />
  );
};
