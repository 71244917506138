import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import { useEffect } from 'react';
import type { MyAssignment } from 'src/apis/assignment-service/types';
import { useCancelUserAssignment } from 'src/apis/assignment-service/use-cancel-user-assignment';
import type { MyAssignmentsOptionalRequest } from 'src/apis/assignment-service/use-my-assignments';
import type { UserAssignmentsForOperatorRequest } from 'src/apis/assignment-service/use-user-assignments-by-operator';
import { useWithdrawUserAssignment } from 'src/apis/assignment-service/use-withdraw-user-assignment';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { DataItems, DataList } from 'src/components/data-display/data-list';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { FormatDate } from 'src/components/data-display/format-date';
import { AssignmentStatusDisplay } from 'src/components/domain-specifics/assignments/assignment-status';
import { CancelUserAssignmentModal } from 'src/components/domain-specifics/assignments/user-assignment/cancel-user-assignment-modal';
import { WithdrawUserAssignmentModal } from 'src/components/domain-specifics/assignments/user-assignment/withdraw-user-assignment-modal';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Heading } from 'src/components/text/heading';
import type { Successful } from 'src/hooks/use-axios';
import { useModal2 } from 'src/hooks/use-modal';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useToast } from 'src/hooks/use-toasts';

type Props = {
  assignmentDetailsRequest: Successful<MyAssignmentsOptionalRequest>;
  cardText?: JSX.Element | string;
  displayAssignmentsButton?: boolean;
  operatorDetails?: PublicOperatorDetailsResponse;
  userAssignments?: Successful<UserAssignmentsForOperatorRequest>;
};

export const AssignmentDetailsUserStatus: FC<Props> = ({
  assignmentDetailsRequest,
  cardText,
  operatorDetails,
  displayAssignmentsButton = true,
  userAssignments,
}) => {
  const assignmentDetails =
    assignmentDetailsRequest.response.data._embedded.items[0];
  const activeUserAssignment = assignmentDetails.userAssignmentStatus;
  return (
    <Card data-testid="user-assignment-card">
      <Stack gap={1}>
        <Stack gap={1} autoColumns="1fr auto" flow="column">
          <Heading mode="card">My Assignment Status</Heading>
          {displayAssignmentsButton && operatorDetails && (
            <ActionButtons
              assignmentDetails={assignmentDetails}
              assignmentDetailsRequest={assignmentDetailsRequest}
              operatorDetails={operatorDetails}
              userAssignments={userAssignments}
            />
          )}
        </Stack>

        <Stack flow="row" gap={1} justifyContent="space-between">
          {!activeUserAssignment ? (
            <EmptyValue label={cardText} />
          ) : (
            <>
              <Stack gap={0.5} justifyContent="start" flow="column" inline>
                <AssignmentStatusDisplay
                  value={activeUserAssignment}
                  data-testid={`user-assignment-status-${assignmentDetails.operatorId}`}
                />
                {assignmentDetails.userAssignmentCancellationReason && (
                  <Tooltip
                    dataTestId={`user-assignment-status-${assignmentDetails.operatorId}-tooltip`}
                    content={assignmentDetails.userAssignmentCancellationReason}
                  >
                    {(targetProps) => (
                      <FontAwesomeIcon {...targetProps} icon={faInfoCircle} />
                    )}
                  </Tooltip>
                )}
              </Stack>
              {displayAssignmentsButton &&
                assignmentDetails.userAssignmentLastChangedAt && (
                  <div data-testid="user-assignment-last-changed-at">
                    <DataList>
                      <DataItems>
                        <span>Last Changed</span>
                        <span>
                          <FormatDate
                            value={
                              assignmentDetails.userAssignmentLastChangedAt
                            }
                            type="date-time"
                          />
                        </span>
                      </DataItems>
                    </DataList>
                  </div>
                )}
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

const ActionButtons: FC<{
  assignmentDetails: MyAssignment;
  assignmentDetailsRequest: Successful<MyAssignmentsOptionalRequest>;
  operatorDetails: PublicOperatorDetailsResponse;
  userAssignments?: Successful<UserAssignmentsForOperatorRequest>;
}> = ({
  assignmentDetails,
  assignmentDetailsRequest,
  operatorDetails,
  userAssignments,
}) => {
  const currentUser = useAuthenticatedMonolithUser();
  const withdrawAssignment = useWithdrawUserAssignment();
  const cancelAssignment = useCancelUserAssignment();
  const notify = useToast();

  const withdraw = useModal2({
    modal: (props) => {
      return (
        <WithdrawUserAssignmentModal
          onConfirm={() => {
            withdrawAssignment.execute({
              url: assignmentDetails._links!.withdrawUserAssignment!.href, // its always there as we check for the link down in this component
              data: { reason: null }, // due to BE implementation and usage of the same endpoint, we need to send NULL
            });
          }}
          operatorDetails={operatorDetails}
          pending={withdrawAssignment.pending}
          {...props}
        />
      );
    },
    trigger: (props) => (
      <Button
        data-testid="withdraw-user-assignment-btn"
        disabled={withdrawAssignment.pending}
        size="small"
        mode="primary"
        {...props}
      >
        {withdrawAssignment.pending ? 'Pending...' : 'Withdraw User Assignment'}
      </Button>
    ),
  });

  const cancel = useModal2({
    modal: (props) => {
      return (
        <CancelUserAssignmentModal
          onConfirm={() => {
            cancelAssignment.execute({
              url: assignmentDetails._links!.cancelUserAssignment!.href,
              data: { reason: null }, // due to BE implementation and usage of the same endpoint, we need to send NULL
            });
          }}
          operatorDetails={operatorDetails}
          pending={cancelAssignment.pending}
          {...props}
        />
      );
    },
    trigger: (props) => (
      <Button
        data-testid="cancel-assignment-btn"
        disabled={cancelAssignment.pending}
        size="small"
        mode="primary"
        {...props}
      >
        {cancelAssignment.pending ? 'Pending...' : 'Cancel User Assignment'}
      </Button>
    ),
  });

  useEffect(() => {
    if (!withdrawAssignment.response) return;
    notify({
      type: 'success',
      children: 'User application was successfully withdrawn.',
    });
    withdraw.close();
    assignmentDetailsRequest.refresh!();
    userAssignments?.refresh!();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawAssignment.response]);

  useEffect(() => {
    if (!cancelAssignment.response) return;
    notify({
      type: 'success',
      children: 'User application was successfully canceled.',
    });
    cancel.close();
    assignmentDetailsRequest.refresh!();
    userAssignments?.refresh!();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelAssignment.response]);

  // if there's no active user assignment, admin can apply directly
  if (!assignmentDetails.userAssignmentStatus) {
    return (
      <Link
        size="small"
        mode="button-primary"
        to="/operators/operator-assignments/apply-for-assignment"
        state={{
          selectedUsers: [
            {
              userId: currentUser.userUuid,
              userName: `${currentUser.firstName} ${currentUser.lastName}`,
              userEmail: currentUser.email,
            },
          ],
          selectedOperator: assignmentDetails.operatorId,
        }}
      >
        Apply for New Assignment
      </Link>
    );
  }

  return (
    <>
      {withdraw.modal}
      {cancel.modal}
      {assignmentDetails._links?.withdrawUserAssignment && withdraw.trigger}
      {assignmentDetails._links?.cancelUserAssignment && cancel.trigger}
    </>
  );
};
