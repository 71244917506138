import { useEffect } from 'react';
import type { UserAssignmentAutoApprovalResponse } from 'src/apis/assignment-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useOptionalUserAssignmentAutoApproval(url?: string) {
  const userAssignmentAutoApproval = useAxios(
    (axios, baseConfig, { url }: { url: string }) =>
      axios.request<UserAssignmentAutoApprovalResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!url) return;
    userAssignmentAutoApproval.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (!url) return;

  return userAssignmentAutoApproval;
}

export type UserAssignmentAutoApprovalRequest = ReturnType<
  typeof useOptionalUserAssignmentAutoApproval
>;
