import type { ComponentPropsWithoutRef, FC } from 'react';
import styled from 'styled-components';
import { Colors } from 'src/styles';

type FormHintProps = ComponentPropsWithoutRef<'small'> & {
  isDisabled?: boolean;
  isSelected?: boolean;
};

const StyledFormHint = styled.small<FormHintProps>`
  font-size: 1.2rem;
  color: ${({ isDisabled, isSelected }) =>
    isDisabled ? Colors.inactive : isSelected ? 'white' : Colors.brandLight2};
`;

export const FormHint: FC<FormHintProps> = (props) => {
  return (
    <div>
      <StyledFormHint {...props} />
    </div>
  );
};
