import escapedString from 'escape-string-regexp';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { z } from 'zod';
import type { AssignedShippersPageResponse } from 'src/apis/monolith/types';
import { SearchableMultiSelect } from 'src/components/form/select/searchable-multi-select';
import { formOptionSchema } from 'src/components/form/zod-schemas';
import { useAxios } from 'src/hooks/use-axios';
import { useSearch } from 'src/hooks/use-search';

type Props = {
  name?: string;
  label: string;
  placeholder: string;
};

export const shipperOptionSchema = formOptionSchema.merge(
  z.object({ shipperRefId: z.coerce.number() })
);

type ShipperOption = z.input<typeof shipperOptionSchema>;

export const ShippersSearchableMultiSelect: FC<Props> = ({
  label,
  placeholder,
  name = 'shipperEic',
}) => {
  const [searchValue, setSearchValue, searchQuery] = useSearch('', {
    minLength: 1,
  });

  const searchRequest = useAxios(
    (axios, baseConfig) =>
      axios.request<AssignedShippersPageResponse>({
        ...baseConfig,
        params: {
          sortColumn: 'shipperName',
          start: 0,
          pageSize: 100000,
          sortAscending: true,
        },
        url: `${PRISMA_CONFIG.monolithApiUrl}/tso/assigned-shippers/page`,
      }),
    { neededOnPageLoad: false }
  );

  // on the very first search input we try to fetch the initial list
  useEffect(() => {
    if (!searchQuery || searchRequest.response) return;
    searchRequest.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchRequest.response]);

  const searchResult = useMemo(() => {
    if (!searchQuery || !searchRequest.response) return;

    const escapedSearchQuery = new RegExp(
      escapedString(searchQuery.toLowerCase())
    );

    return searchRequest.response.data.data
      .filter(
        (shipper) =>
          shipper.shipperName.toLowerCase().match(escapedSearchQuery) ||
          shipper.eic.toLowerCase().match(escapedSearchQuery)
      )
      .map((shipper) => ({
        value: shipper.eic,
        label: shipper.shipperName,
        shipperRefId: shipper.shipperRefId,
      })) satisfies ShipperOption[];
  }, [searchQuery, searchRequest.response]);

  return (
    <SearchableMultiSelect
      label={label}
      name={name}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      searchValueForCurrentOptions={searchValue}
      pending={searchRequest.pending}
      options={searchResult}
      stacked
      placeholder={placeholder}
      renderOptionHint={(option) => option.value}
      renderSelectedOptionTooltip={(option) => (
        <>
          <strong>EIC:</strong> {option.value}
        </>
      )}
    />
  );
};
