import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import type {
  AdditionalRequiredDocument,
  RequiredCreditRatingDocument,
  RequiredDocument,
} from 'src/apis/assignment-service/types';
import type { AssignmentInfoRequest } from 'src/apis/assignment-service/use-assignment-info';
import { useDownloadRequiredInformation } from 'src/apis/assignment-service/use-download-required-document';
import type { PublicRequiredDocumentsRequest } from 'src/apis/assignment-service/use-public-required-documents';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import type { OptionalOrganisationDetailsRequest } from 'src/apis/organisation-service/use-organisation-details';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { Divider } from 'src/components/dividers';
import { Alert } from 'src/components/feedback/alert';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Heading } from 'src/components/text/heading';
import type { Successful } from 'src/hooks/use-axios';
import { DeliveryMethodLabels } from 'src/pages/settings/assignment-settings/required-information/edit/schema';
import { Colors } from 'src/styles';

type Props = {
  publicRequiredDocuments: Successful<PublicRequiredDocumentsRequest>;
  tsoDetails: PublicOperatorDetailsResponse;
  assignmentInfo: Successful<AssignmentInfoRequest>;
  organisationDetails?: Successful<OptionalOrganisationDetailsRequest>;
};

export const CompanyRequiredDocuments: FC<Props> = ({
  publicRequiredDocuments,
  tsoDetails,
  assignmentInfo,
  organisationDetails,
}) => {
  const { additionalOrganisationDocuments, additionalUserDocuments } =
    publicRequiredDocuments.response.data;

  const hasAdditionalDocs =
    additionalOrganisationDocuments.length > 0 ||
    additionalUserDocuments.length > 0;
  return (
    <Card id="documents">
      <Stack gap={1}>
        <Heading mode="card">Documents</Heading>
        {hasAdditionalDocs && (
          <Alert type="info">
            Content available for download has been provided by the operator.
            Users are encouraged to take appropriate precautions, such as virus
            scans and malware checks, before accessing the files. PRISMA assumes
            no responsibility for any issues or security risks that may arise
            from using these files.
          </Alert>
        )}
        <Divider />
        <ExternalLink
          target="_blank"
          mode="default-underlined"
          rel="noopener noreferrer"
          hideIcon={true}
          href={tsoDetails.gtcUrl}
        >
          General Terms and Conditions <FontAwesomeIcon icon={faExternalLink} />
        </ExternalLink>
        <Divider />

        <AssignmentPublicRequiredDocuments
          publicRequiredDocuments={publicRequiredDocuments}
          assignmentInfo={assignmentInfo}
          organisationDetails={organisationDetails}
        />
      </Stack>
    </Card>
  );
};

const AssignmentPublicRequiredDocuments: FC<{
  publicRequiredDocuments: Successful<PublicRequiredDocumentsRequest>;
  assignmentInfo: Successful<AssignmentInfoRequest>;
  organisationDetails?: Successful<OptionalOrganisationDetailsRequest>;
}> = ({ publicRequiredDocuments, assignmentInfo, organisationDetails }) => {
  const { data } = publicRequiredDocuments.response;

  const anyRequiredDocuments: boolean = useMemo(
    () =>
      [
        data.companyRegisterExtract,
        data.currentCompanyReport,
        data.mostRecentFullYearFinancialReport,
        data.mostRecentInterimFinancialReport,
        data.creditSolvencyCheck,
        data.poa,
        data.creditRating,
      ].some((doc) => doc && doc.acceptedDeliveryMethods.length > 0),
    [data]
  );

  return (
    <Stack gap={0.5}>
      <p>
        Following documents are required when requesting an assignment with this
        operator:
      </p>

      {!anyRequiredDocuments && <p>There are no documents.</p>}

      {anyRequiredDocuments && (
        <>
          <DocumentTitle>New Organisation Must Provide</DocumentTitle>
          <Stack gap={0.5} data-testid="documents-list">
            {data.companyRegisterExtract && (
              <DocumentView
                label="Company Register Extract"
                document={data.companyRegisterExtract}
              />
            )}
            {data.currentCompanyReport && (
              <DocumentView
                label="Current Company Report"
                document={data.currentCompanyReport}
              />
            )}
            {data.mostRecentFullYearFinancialReport && (
              <DocumentView
                label="Most Recent (Full-Year) Financial Report"
                document={data.mostRecentFullYearFinancialReport}
              />
            )}
            {data.mostRecentInterimFinancialReport && (
              <DocumentView
                label="Most Recent (Interim) Financial Report"
                document={data.mostRecentInterimFinancialReport}
              />
            )}
            {data.creditSolvencyCheck && (
              <DocumentView
                label="Credit/Solvency Check Document"
                document={data.creditSolvencyCheck}
              />
            )}
            {data.poa && (
              <DocumentView
                label="PRISMA Shipper Admin Power of Attorney"
                document={data.poa}
              />
            )}
            {data.creditRating && (
              <DocumentView
                label="Credit Rating Certification"
                document={data.creditRating}
                agency={data.creditRating.agency}
              />
            )}
          </Stack>
        </>
      )}

      {data.additionalOrganisationDocuments.length > 0 && (
        <Stack gap={0.5} data-testid="custom-documents-list">
          {data.additionalOrganisationDocuments.map((organisationDocument) => (
            <CustomDocumentView
              key={organisationDocument.name}
              additionalDocument={organisationDocument}
            />
          ))}
        </Stack>
      )}

      {data.additionalUserDocuments.length > 0 && (
        <>
          <DocumentTitle>New Users Must Provide</DocumentTitle>
          <Stack gap={0.5} data-testid="user-documents-list">
            {data.additionalUserDocuments.map((userDocument) => (
              <CustomDocumentView
                key={userDocument.name}
                additionalDocument={userDocument}
              />
            ))}
          </Stack>
        </>
      )}

      {assignmentInfo.response.data.dvgwCodeRequiredForAssignment && (
        <Stack gap={1} data-testid="dvgw-code-configuration">
          <Divider />
          <p>
            This operator needs a supplier DVGW code for an assignment.
            {/* check if the shipper is logged-in */}
            {organisationDetails?.response.data._links.shipperDetails && (
              <>
                {' '}
                You have to provide this code in your{' '}
                <Link
                  mode="default-underlined"
                  to="/settings/organisation/company-information"
                >
                  Shipper Details
                </Link>
                .
              </>
            )}
          </p>
        </Stack>
      )}
    </Stack>
  );
};

export const DocumentTitle: FC<{ children: ReactNode }> = ({ children }) => (
  <SpacedStack flow="column" justifyContent="space-between">
    <h4>{children}</h4>
  </SpacedStack>
);

export const DocumentList: FC<{
  items: { label: React.ReactNode; appendix?: string }[];
}> = ({ items }) => (
  <ul style={{ margin: 0 }}>
    {items.map(({ label, appendix }, index) => (
      <li key={index}>
        {label}
        {appendix && <Appendix>{appendix}</Appendix>}
      </li>
    ))}
  </ul>
);

type DocumentViewProps = {
  label: string;
  document?: RequiredDocument | RequiredCreditRatingDocument;
  agency?: string | null;
};

export const DocumentView: FC<DocumentViewProps> = ({
  label,
  document,
  agency,
}) => {
  if (!document?.acceptedDeliveryMethods.length) return null;

  const documentItems = [
    {
      label: `${label} ${agency ? `(Agency: ${agency})` : ''}`,
      appendix: document.acceptedDeliveryMethods
        .map((method) => DeliveryMethodLabels[method])
        .join(', '),
    },
  ];

  return (
    <Stack flow="column" justifyContent="space-between">
      <DocumentList items={documentItems} />
    </Stack>
  );
};

type CustomDocumentViewProps = {
  additionalDocument: AdditionalRequiredDocument;
};

export const CustomDocumentView: FC<CustomDocumentViewProps> = ({
  additionalDocument,
}) => {
  const download = useDownloadRequiredInformation();

  const createDocumentItem = (label: React.ReactNode) => ({
    label,
    appendix: additionalDocument.acceptedDeliveryMethods
      .map((method) => DeliveryMethodLabels[method])
      .join(', '),
  });

  const documentItems = [];

  if (additionalDocument.url) {
    documentItems.push(
      createDocumentItem(
        <ExternalLink
          href={additionalDocument.url}
          target="_blank"
          mode="default-underlined"
          rel="noopener noreferrer"
        >
          {additionalDocument.name}
        </ExternalLink>
      )
    );
  }

  if (additionalDocument.document?._links.download.href) {
    documentItems.push(
      createDocumentItem(
        <Tooltip content="Content available for download has been provided by the operator. PRISMA assumes no responsibility for any issues or security risks that may arise from using these files.">
          {(targetProps) => (
            <Button
              mode="link-underlined"
              icon="download"
              onClick={() =>
                download.execute({
                  url: additionalDocument.document!._links.download.href,
                })
              }
              disabled={download.pending}
              {...targetProps}
            >
              {additionalDocument.name}
            </Button>
          )}
        </Tooltip>
      )
    );
  }

  if (!additionalDocument.url && !additionalDocument.document) {
    documentItems.push(createDocumentItem(additionalDocument.name));
  }

  return <DocumentList items={documentItems} />;
};

const SpacedStack = styled(Stack)`
  margin-top: 1rem;
`;
const Appendix = styled.span`
  color: ${Colors.brandLight2};
  font-size: 1.3rem;
  display: block;
`;
