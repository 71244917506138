import {
  faArrowFromRight,
  faDatabase,
  faEllipsisHAlt,
  faStream,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import {
  mainServiceTypeLabels,
  offerStatusLabels,
  serviceTypesFilterLabels,
  serviceTypesToLowerCaseLabels,
} from 'src/apis/lng-marketing/mappings';
import type {
  LngOfferDetails,
  LngOffersOverviewParams,
  OfferStatus,
  ServiceType,
  ServiceTypeLowerCase,
  ServiceTypes,
} from 'src/apis/lng-marketing/types';
import type { LngMarketingRootRequest } from 'src/apis/lng-marketing/use-lng-marketing-root';
import { StatusDisplay } from 'src/components/data-display/status-display';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import type { Successful } from 'src/hooks/use-axios';
import { FormatLngTimerForTable, useTimer } from 'src/hooks/use-timer';
import type { LngOfferFormValues } from 'src/pages/lng/offers/create/schema';
import { Colors } from 'src/styles';

export const OfferStatusWithTimer: FC<{
  status: OfferStatus;
  countdownEnd?: string | null;
  lngMarketingRoot?: Successful<LngMarketingRootRequest>;
}> = ({ status, countdownEnd, lngMarketingRoot }) => {
  return (
    <StatusDisplay
      iconColor={getStatusColor(status)}
      status={offerStatusLabels[status]}
      appendix={
        countdownEnd && (
          <Countdown
            timestamp={countdownEnd}
            lngMarketingRoot={lngMarketingRoot}
          />
        )
      }
    />
  );
};

export function getStatusColor(status: OfferStatus) {
  switch (status) {
    case 'OPEN':
      return Colors.ready;
    case 'FINISHED':
    case 'ADDITIONAL_SERVICE_BOOKING_OPEN':
      return Colors.success;
    case 'CANCELLED':
      return Colors.inactive;
    case 'UNSUCCESSFUL':
    case 'REJECTED':
    case 'FAILED':
      return Colors.error;
    case 'CREATED':
    case 'PUBLISHED':
    case 'WAITING_FOR_NEXT_ROUND':
    case 'ALLOCATION_PENDING':
    case 'APPROVAL_PENDING':
      return Colors.warning;
  }
}

const Countdown: FC<{
  timestamp: string;
  lngMarketingRoot?: Successful<LngMarketingRootRequest>;
}> = ({ timestamp, lngMarketingRoot }) => {
  const timer = useTimer(timestamp);

  if (!timer) return null;
  return (
    <FormatLngTimerForTable timer={timer} lngMarketingRoot={lngMarketingRoot} />
  );
};

export const AvailableServices: FC<{
  services: Array<ServiceType>;
}> = ({ services }) => {
  const correctOrderServices: Array<ServiceType> = [
    'UNLOADING',
    'STORAGE',
    'REGASIFICATION',
    'TRANSPORT',
  ];
  return (
    <Stack flow="column" justifyContent="space-between">
      {correctOrderServices.map((service) => (
        <span key={service}>{getIconWithTooltip(service, services)}</span>
      ))}
    </Stack>
  );
};

const getIconWithTooltip = (
  service: ServiceType,
  services: Array<ServiceType>
) => {
  switch (service) {
    case 'UNLOADING':
      return services.includes('UNLOADING') ? (
        <Tooltip content={<small>Unloading</small>}>
          {(targetProps) => (
            <FontAwesomeIcon icon={faArrowFromRight} {...targetProps} />
          )}
        </Tooltip>
      ) : (
        <Tooltip content={<small>Unloading</small>}>
          {(targetProps) => (
            <FontAwesomeIcon
              color={Colors.inactiveLight}
              icon={faArrowFromRight}
              {...targetProps}
            />
          )}
        </Tooltip>
      );
    case 'STORAGE':
      return services.includes('STORAGE') ? (
        <Tooltip content={<small>Storage</small>}>
          {(targetProps) => (
            <FontAwesomeIcon icon={faDatabase} {...targetProps} />
          )}
        </Tooltip>
      ) : (
        <Tooltip content={<small>Storage</small>}>
          {(targetProps) => (
            <FontAwesomeIcon
              color={Colors.inactiveLight}
              icon={faDatabase}
              {...targetProps}
            />
          )}
        </Tooltip>
      );
    case 'REGASIFICATION':
      return services.includes('REGASIFICATION') ? (
        <Tooltip content={<small>Regasification</small>}>
          {(targetProps) => (
            <FontAwesomeIcon icon={faEllipsisHAlt} {...targetProps} />
          )}
        </Tooltip>
      ) : (
        <Tooltip content={<small>Regasification</small>}>
          {(targetProps) => (
            <FontAwesomeIcon
              color={Colors.inactiveLight}
              icon={faEllipsisHAlt}
              {...targetProps}
            />
          )}
        </Tooltip>
      );
    case 'TRANSPORT':
      return services.includes('TRANSPORT') ? (
        <Tooltip content={<small>Transport</small>}>
          {(targetProps) => (
            <FontAwesomeIcon icon={faStream} {...targetProps} />
          )}
        </Tooltip>
      ) : (
        <Tooltip content={<small>Transport</small>}>
          {(targetProps) => (
            <FontAwesomeIcon
              color={Colors.inactiveLight}
              icon={faStream}
              {...targetProps}
            />
          )}
        </Tooltip>
      );
  }
};

export const serviceTypeIcons: Record<ServiceType, JSX.Element> = {
  UNLOADING: <FontAwesomeIcon icon={faArrowFromRight} />,
  STORAGE: <FontAwesomeIcon icon={faDatabase} />,
  REGASIFICATION: <FontAwesomeIcon icon={faEllipsisHAlt} />,
  TRANSPORT: <FontAwesomeIcon icon={faStream} />,
};

export const serviceTypeIconsLowercase: Record<
  ServiceTypeLowerCase,
  JSX.Element
> = {
  unloading: <FontAwesomeIcon icon={faArrowFromRight} />,
  storage: <FontAwesomeIcon icon={faDatabase} />,
  regasification: <FontAwesomeIcon icon={faEllipsisHAlt} />,
  transport: <FontAwesomeIcon icon={faStream} />,
};

export const additionalServiceTypeIcons: Record<ServiceTypes, JSX.Element> = {
  UnloadingServiceJson: <FontAwesomeIcon icon={faArrowFromRight} />,
  StorageServiceJson: <FontAwesomeIcon icon={faDatabase} />,
  RegasificationServiceJson: <FontAwesomeIcon icon={faEllipsisHAlt} />,
  TransportServiceJson: <FontAwesomeIcon icon={faStream} />,
};

export const lngOffersEmptyParams: LngOffersOverviewParams = {
  limit: 0, // we just need the create link
};

export const convertMinutesToDate = (minutes: number) => {
  if (minutes < 60) {
    return `${minutes}min`;
  } else if (minutes < 1440) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}min`;
  } else {
    const days = Math.floor(minutes / 1440);
    const remainingMinutes = minutes % 1440;
    const hours = Math.floor(remainingMinutes / 60);
    const mins = remainingMinutes % 60;
    return `${days}d ${hours > 0 ? `${hours}h` : ''} ${mins}min`;
  }
};

export const convertHoursMinutesToTime = (hours: number, minutes: number) => {
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};

export const convertTimeInMinutesToDaysHoursMinutes = (
  timeInMinutes: number
) => {
  const days = Math.floor(timeInMinutes / (60 * 24));
  let remainingMinutes = timeInMinutes % (60 * 24);
  const hours = Math.floor(remainingMinutes / 60);
  remainingMinutes = remainingMinutes % 60;
  const minutes = remainingMinutes % 60;
  return { days, hours, minutes };
};

export const convertTimeInMinutesToHoursMinutes = (timeInMinutes: number) => {
  const hours = Math.floor(timeInMinutes / 60);
  let remainingMinutes = timeInMinutes % 60;
  const minutes = remainingMinutes % 60;
  return { hours, minutes };
};

export function getLabelForUnitOfThePriceInForm(values: LngOfferFormValues) {
  if (!values.bidOnUnitOfService) {
    return '';
  }

  if (
    values[values.bidOnUnitOfService]?.displayName &&
    values[values.bidOnUnitOfService]!.displayName!.trim().split(/\s+/).length <
      4
  ) {
    return values[values.bidOnUnitOfService]?.displayName;
  }
  return mainServiceTypeLabels[values.bidOnUnitOfService];
}

export function getLabelForUnitOfThePrice(data: LngOfferDetails) {
  if (!data.unitPriceConfiguration) {
    return '';
  }

  if (
    data.mainServices[
      serviceTypesToLowerCaseLabels[data.unitPriceConfiguration.serviceType]
    ]?.displayName &&
    data.mainServices[
      serviceTypesToLowerCaseLabels[data.unitPriceConfiguration.serviceType]
    ]!.displayName!.trim().split(/\s+/).length < 4
  ) {
    return data.mainServices[
      serviceTypesToLowerCaseLabels[data.unitPriceConfiguration.serviceType]
    ]?.displayName;
  }

  return serviceTypesFilterLabels[data.unitPriceConfiguration.serviceType];
}
