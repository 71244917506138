import type { FC } from 'react';
import { lazy, useMemo } from 'react';
import type { ShipperAssignmentsOverviewParams } from 'src/apis/assignment-service/types';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useShipperAssignments } from 'src/apis/assignment-service/use-shipper-assignments';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useOperatorsOverviewPageParams } from 'src/pages/operators/assignments/overview/use-page-params';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const monolithAssignmentEndpoints = {
  getAssignmentsOfShipperUser: `${PRISMA_CONFIG.monolithApiUrl}/shippers/{customerUserRefId}/assigned-operators?detailLevel=EXTENDED_PUBLIC`,
  getAllTsos: `${PRISMA_CONFIG.monolithApiUrl}/tso`,
};

const UserAssignmentsOverviewPage: FC<{
  assignmentService: Successful<AssignmentServiceRequest>;
}> = ({ assignmentService }) => {
  // prefer "admin" link over "user" link
  const shipperAssignmentsLink =
    assignmentService.response.data._links.shipperAdminAssignments ??
    assignmentService.response.data._links.shipperUserAssignments;

  if (!shipperAssignmentsLink) throw new Forbidden();
  const pageParams = useOperatorsOverviewPageParams();

  const params = useMemo(() => {
    return {
      sortBy: pageParams.value.sortBy,
      sortDirection: pageParams.value.sortDirection,
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
      operatorId: pageParams.value.operatorId?.value,
      organisationAssignmentStatus:
        pageParams.value.organisationAssignmentStatus,
      tradingMarket: pageParams.value.tradingMarket,
      userName: pageParams.value.userName,
      userEmail: pageParams.value.userEmail,
      userAssignmentStatus: pageParams.value.userAssignmentStatus,
      allActive: pageParams.value.allActive,
      ownAssigned: pageParams.value.ownAssigned,
    } satisfies ShipperAssignmentsOverviewParams;
  }, [pageParams.value]);

  const assignments = useShipperAssignments({
    url: shipperAssignmentsLink.href,
    params,
  });
  if (!assignments.response) return <PageSpinner />;

  return (
    <Page
      pageParams={pageParams}
      assignmentService={assignmentService}
      assignments={assignments}
    />
  );
};

export default UserAssignmentsOverviewPage;
