import { faBan } from '@fortawesome/pro-solid-svg-icons';
import type { FC } from 'react';
import type { ShipperOrganisationAssignmentResponse } from 'src/apis/assignment-service/types';
import type { CancelOrganisationAssignmentsRequest } from 'src/apis/assignment-service/use-cancel-organisational-assignment';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Form } from 'src/components/form/form';
import { Textarea } from 'src/components/form/textarea';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Modal } from 'src/components/overlay/modal';
import { useCountdown } from 'src/hooks/use-countdown';
import { cancelOrganisationOrUserAssignmentSchema } from 'src/pages/shippers/details/information/schema';

type Props = {
  close: () => void;
  onConfirm: () => void;
  pending: boolean;
  operatorDetails: PublicOperatorDetailsResponse;
};

export const CancelOrgAssignmentModal: FC<Props> = ({
  close,
  onConfirm,
  pending,
  operatorDetails,
}) => {
  const seconds = useCountdown();

  return (
    <Modal
      data-testid="cancel-assignment-modal"
      title="Cancel Organisation Assignment"
      icon={faBan}
      onDismiss={close}
      footer={{
        confirmButton: {
          label: seconds
            ? `Wait ${seconds}s to proceed...`
            : pending
              ? 'Pending...'
              : 'Cancel Assignment',
          onClick: onConfirm,
          disabled: pending || seconds !== 0,
          dataTestId: seconds === 0 ? 'cancel-assignment-submit' : undefined,
        },
      }}
    >
      <Stack>
        <p data-testid="cancel-assignment-content">
          Please confirm that you want to cancel the organisation assignment and
          all user assignments to{' '}
          <strong>
            <ExternalLink href={operatorDetails.gtcUrl}>
              {operatorDetails.shortName}
            </ExternalLink>
          </strong>
          .
          <br />
          <br />
          The cancellation of an organisation assignment will also cancel all
          user assignments.
        </p>
      </Stack>
    </Modal>
  );
};

type CancelOrganisationAssignmentModalProps = {
  close: () => void;
  pending: boolean;
  cancel: CancelOrganisationAssignmentsRequest;
  assignedShipper: ShipperOrganisationAssignmentResponse;
  companyName: string;
};

export const ShipperUserAssignmentCancelModal: FC<
  CancelOrganisationAssignmentModalProps
> = ({ close, pending, cancel, assignedShipper, companyName }) => {
  const seconds = useCountdown();

  return (
    <Form
      initialValues={{ reason: '' }}
      onSubmit={(values) => {
        cancel.execute({
          url: assignedShipper._links.cancel!.href,
          data: { reason: values.reason },
        });
      }}
      constraintViolation={cancel.error}
      schema={cancelOrganisationOrUserAssignmentSchema}
      cancelOrResetButton={null}
      submitButton={null}
    >
      <Modal
        data-testid="cancel-assignment-modal"
        title="Cancel Assignment"
        onDismiss={close}
        footer={{
          submitButton: {
            label: seconds
              ? `Wait ${seconds}s to proceed...`
              : pending
                ? 'Pending...'
                : 'Cancel Assignment',
            disabled: pending || seconds !== 0,
            dataTestId:
              seconds === 0 ? 'cancel-org-assignment-submit' : undefined,
          },
        }}
      >
        <Stack gap={1.5}>
          <p>
            Please confirm that you want to cancel the assignment for{' '}
            <strong>{companyName}</strong>.
          </p>
          <p>
            Cancellation of the organisation assignment will also cancel all
            user assignments.
          </p>
          <Textarea
            stacked
            name="reason"
            label="Reason for Cancellation"
            info="The reason for cancellation will be displayed to the user"
            markAsRequired
            placeholder="Please enter the reason for cancelling the assignment..."
          />
        </Stack>
      </Modal>
    </Form>
  );
};
