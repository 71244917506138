import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCancelOrganisationAssignment } from 'src/apis/assignment-service/use-cancel-organisational-assignment';
import type { MyAssignmentsOptionalRequest } from 'src/apis/assignment-service/use-my-assignments';
import type { UserAssignmentsForOperatorRequest } from 'src/apis/assignment-service/use-user-assignments-by-operator';
import { useWithdrawOrganisationalAssignment } from 'src/apis/assignment-service/use-withdraw-organisational-assignment';
import type { OperatorRequest } from 'src/apis/contract-management/use-operator-by-tso';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { DataItems, DataList } from 'src/components/data-display/data-list';
import { FormatDate } from 'src/components/data-display/format-date';
import { AssignmentStatusDisplay } from 'src/components/domain-specifics/assignments/assignment-status';
import { CancelOrgAssignmentModal } from 'src/components/domain-specifics/assignments/organisation-assignment/cancel-org-assignment-modal';
import { WithdrawOrgAssignmentModal } from 'src/components/domain-specifics/assignments/organisation-assignment/withdraw-org-assignment-modal';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Heading } from 'src/components/text/heading';
import type { Successful } from 'src/hooks/use-axios';
import { useModal2 } from 'src/hooks/use-modal';
import { useToast } from 'src/hooks/use-toasts';

type Props = {
  operatorDetails: PublicOperatorDetailsResponse;
  caMaOperator?: OperatorRequest;
  assignmentDetailsRequest: Successful<MyAssignmentsOptionalRequest>;
  displayAssignmentsButton?: boolean;
  userAssignments?: Successful<UserAssignmentsForOperatorRequest>;
};

export const AssignmentDetailsOrganisationStatus: FC<Props> = ({
  caMaOperator,
  displayAssignmentsButton = true,
  operatorDetails,
  assignmentDetailsRequest,
  userAssignments,
}) => {
  const assignmentDetails =
    assignmentDetailsRequest.response.data._embedded.items[0];
  return (
    <Card data-testid="organisation-assignment-card">
      <Stack gap={1} alignItems="center">
        <Stack gap={1} autoColumns="1fr auto" flow="column">
          <Heading mode="card">Organisation Assignment</Heading>
          {displayAssignmentsButton && (
            <ActionButtons
              assignmentDetailsRequest={assignmentDetailsRequest}
              operatorDetails={operatorDetails}
              userAssignments={userAssignments}
            />
          )}
        </Stack>
        <Stack flow="row" gap={1} justifyContent="space-between">
          <Stack gap={0.5} justifyContent="start" flow="column" inline>
            <AssignmentStatusDisplay
              value={assignmentDetails.organisationAssignmentStatus}
              tradingMarket={assignmentDetails.tradingMarket}
              data-testid={`organisation-assignment-status-${assignmentDetails.operatorId}`}
            />
            {assignmentDetails.organisationAssignmentCancellationReason && (
              <Tooltip
                dataTestId={`organisation-assignment-status-${assignmentDetails.operatorId}-tooltip`}
                content={
                  assignmentDetails.organisationAssignmentCancellationReason
                }
              >
                {(targetProps) => (
                  <FontAwesomeIcon {...targetProps} icon={faInfoCircle} />
                )}
              </Tooltip>
            )}
          </Stack>
          {displayAssignmentsButton && (
            <div data-testid="org-assignment-last-changed-at">
              <DataList>
                <DataItems>
                  <span>Last Changed</span>
                  <span>
                    <FormatDate
                      value={
                        assignmentDetails.organisationAssignmentLastChangedAt
                      }
                      type="date-time"
                    />
                  </span>
                </DataItems>
              </DataList>
            </div>
          )}

          {(caMaOperator?.response?.data ||
            assignmentDetails.organisationAssignmentStatus ===
              'WAITING_FOR_APPROVAL') && (
            <div data-testid="organisation-assignment-info">
              <DataList>
                <DataItems>
                  {!!caMaOperator?.response?.data.allowsNomination && (
                    <>
                      <span>Nomination</span>
                      <span>Allowed</span>
                    </>
                  )}
                </DataItems>
              </DataList>
            </div>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

const ActionButtons: FC<{
  operatorDetails: PublicOperatorDetailsResponse;
  assignmentDetailsRequest: Successful<MyAssignmentsOptionalRequest>;
  userAssignments?: Successful<UserAssignmentsForOperatorRequest>;
}> = ({ assignmentDetailsRequest, operatorDetails, userAssignments }) => {
  const assignmentDetails =
    assignmentDetailsRequest.response.data._embedded.items[0];
  const withdrawAssignment = useWithdrawOrganisationalAssignment();
  const cancelAssignment = useCancelOrganisationAssignment();
  const notify = useToast();
  const navigate = useNavigate();

  const withdraw = useModal2({
    modal: (props) => {
      return (
        <WithdrawOrgAssignmentModal
          onConfirm={() => {
            withdrawAssignment.execute({
              url: assignmentDetails._links!.withdrawOrgAssignment!.href, // its always there as we check for the link in the parent component
            });
          }}
          pending={withdrawAssignment.pending}
          {...props}
        />
      );
    },
    trigger: (props) => (
      <Button
        data-testid="withdraw-org-assignment-btn"
        disabled={withdrawAssignment.pending}
        size="small"
        mode="primary"
        {...props}
      >
        {withdrawAssignment.pending ? 'Pending...' : 'Withdraw Org. Assignment'}
      </Button>
    ),
  });

  const cancel = useModal2({
    modal: (props) => {
      return (
        <CancelOrgAssignmentModal
          onConfirm={() => {
            cancelAssignment.execute({
              url: assignmentDetails._links!.cancelOrgAssignment!.href, // its always there as we check for the link in the parent component
              data: { reason: null }, // due to BE implementation and usage of the same endpoint, we need to send NULL
            });
          }}
          pending={cancelAssignment.pending}
          operatorDetails={operatorDetails}
          {...props}
        />
      );
    },
    trigger: (props) => (
      <Button
        data-testid="cancel-org-assignment-btn"
        disabled={cancelAssignment.pending}
        size="small"
        mode="primary"
        {...props}
      >
        {cancelAssignment.pending ? 'Pending...' : 'Cancel Org. Assignment'}
      </Button>
    ),
  });

  useEffect(() => {
    if (!withdrawAssignment.response) return;
    notify({
      type: 'success',
      children: 'Assignment application was successfully withdrawn.',
    });
    withdraw.close();
    navigate('/operators/operator-assignments/overview');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawAssignment.response]);

  useEffect(() => {
    if (!cancelAssignment.response) return;
    notify({
      type: 'success',
      children: 'Assignment was successfully cancelled.',
    });
    cancel.close();
    assignmentDetailsRequest.refresh!();
    userAssignments?.refresh!();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelAssignment.response]);

  return (
    <>
      {withdraw.modal}
      {cancel.modal}
      {assignmentDetails._links?.withdrawOrgAssignment && withdraw.trigger}
      {assignmentDetails._links?.cancelOrgAssignment && cancel.trigger}
    </>
  );
};
