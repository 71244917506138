import { faBan } from '@fortawesome/pro-solid-svg-icons';
import type { FC } from 'react';
import type { CancelUserAssignmentsRequest } from 'src/apis/assignment-service/use-cancel-user-assignment';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Form } from 'src/components/form/form';
import { Textarea } from 'src/components/form/textarea';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Modal } from 'src/components/overlay/modal';
import { useCountdown } from 'src/hooks/use-countdown';
import { cancelOrganisationOrUserAssignmentSchema } from 'src/pages/shippers/details/information/schema';

type Props = {
  close: () => void;
  onConfirm: () => void;
  pending: boolean;
  operatorDetails: PublicOperatorDetailsResponse;
  userName?: string;
};

export const CancelUserAssignmentModal: FC<Props> = ({
  close,
  onConfirm,
  pending,
  operatorDetails,
  userName,
}) => {
  const seconds = useCountdown();

  return (
    <Modal
      data-testid="cancel-user-assignment-modal"
      title="Cancel User Assignment"
      icon={faBan}
      onDismiss={close}
      footer={{
        confirmButton: {
          label: seconds
            ? `Wait ${seconds}s to proceed...`
            : pending
              ? 'Pending...'
              : 'Cancel Assignment',
          onClick: onConfirm,
          disabled: pending || seconds !== 0,
          dataTestId:
            seconds === 0 ? 'cancel-user-assignment-submit' : undefined,
        },
      }}
    >
      <Stack gap={1.5}>
        <p data-testid="cancel-assignment-content">
          {userName ? (
            <>
              Please confirm that you want to cancel the assignment of{' '}
              {userName} to{' '}
              <strong>
                <ExternalLink href={operatorDetails.gtcUrl}>
                  {operatorDetails.shortName}
                </ExternalLink>
              </strong>
              .
            </>
          ) : (
            <>
              Please confirm that you want to cancel your assignment to{' '}
              <strong>
                <ExternalLink href={operatorDetails.gtcUrl}>
                  {operatorDetails.shortName}
                </ExternalLink>
              </strong>
              .
            </>
          )}
        </p>
      </Stack>
    </Modal>
  );
};

type CancelUserAssignmentModalProps = {
  close: () => void;
  pending: boolean;
  cancel: CancelUserAssignmentsRequest;
  userName: string;
  url: string;
};

export const ShipperUserAssignmentCancelModal: FC<
  CancelUserAssignmentModalProps
> = ({ close, pending, cancel, userName, url }) => {
  const seconds = useCountdown();

  return (
    <Form
      initialValues={{ reason: '' }}
      onSubmit={(values) => {
        cancel.execute({
          url,
          data: { reason: values.reason },
        });
      }}
      constraintViolation={cancel.error}
      schema={cancelOrganisationOrUserAssignmentSchema}
      cancelOrResetButton={null}
      submitButton={null}
    >
      <Modal
        data-testid="cancel-user-assignment-modal"
        title="Cancel User Assignment"
        onDismiss={close}
        footer={{
          submitButton: {
            label: seconds
              ? `Wait ${seconds}s to proceed...`
              : pending
                ? 'Pending...'
                : 'Cancel Assignment',
            disabled: pending || seconds !== 0,
            dataTestId:
              seconds === 0 ? 'cancel-user-assignment-submit' : undefined,
          },
        }}
      >
        <Stack gap={1.5}>
          <p>
            Please confirm that you want to cancel the user assignment for{' '}
            <strong>{userName}</strong>.
          </p>

          <Textarea
            stacked
            name="reason"
            info="The reason for cancellation will be displayed to the user"
            label="Reason for Cancellation"
            markAsRequired
            placeholder="Please enter the reason for cancelling the assignment..."
          />
        </Stack>
      </Modal>
    </Form>
  );
};
