import { lazy } from 'react';
import { useInfrastructurePoints } from 'src/apis/prototype-map/use-infrastructure-points';
import { useMapPrototype } from 'src/apis/prototype-map/use-map-prototype';
import { AutoFullwidth } from 'src/components/layout/auto-fullwidth';
import { PageSpinner } from 'src/components/spinner-container';
import countriesGeoJson from 'src/pages/prototype-map/market-areas-and-countries-europe.json';
import pipelinesGeoJson from 'src/pages/prototype-map/pipelines-prisma.json';
import type {
  CountriesGeoJson,
  PipelinesGeoJson,
} from 'src/pages/prototype-map/utils';

const Page = lazy(() => import('./page'));

export const PrototypeMap = () => {
  const mapPrototype = useMapPrototype();
  const infrastructurePoints = useInfrastructurePoints({
    mapPrototype,
    params: {
      type: ['CROSS_BORDER_INTERCONNECTION_BUNDLE', 'STORAGE', 'H2', 'LNG'],
      minLat: 0,
      maxLat: 90,
      minLon: -180,
      maxLon: 180,
    },
  });

  if (!mapPrototype?.response || !infrastructurePoints?.response)
    return <PageSpinner />;

  return (
    <>
      <AutoFullwidth />
      <Page
        countriesGeoJson={countriesGeoJson as CountriesGeoJson}
        pipelinesGeoJson={pipelinesGeoJson as PipelinesGeoJson}
        mapPrototype={mapPrototype}
        infrastructurePoints={infrastructurePoints}
      />
    </>
  );
};
