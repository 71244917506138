import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  OperatorUserAssignmentParams,
  OperatorUserAssignmentsResponse,
} from 'src/apis/assignment-service/types';

import { useAxios } from 'src/hooks/use-axios';

type HookProps = {
  url: string;
  shipperId: string;
  params: OperatorUserAssignmentParams;
};

type ExecuteProps = {
  url: string;
  params: OperatorUserAssignmentParams;
};

export function useAssignedShipperUsersForOperator({
  url,
  shipperId,
  params,
}: HookProps) {
  const assignedUsers = useAxios(
    (axios, baseConfig, { url, params }: ExecuteProps) =>
      axios.request<OperatorUserAssignmentsResponse>({
        ...baseConfig,
        url,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    const assignedUsersUrl = urlTemplate.parse(url).expand({ shipperId });
    assignedUsers.execute({ url: assignedUsersUrl, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params, shipperId]);

  return assignedUsers;
}

export type AssignedShipperUsersForOperatorRequest = ReturnType<
  typeof useAssignedShipperUsersForOperator
>;
